import { usePopperTooltip } from "react-popper-tooltip"

// placement: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end';

export default function usePopperPosition(
  position,
  clickType,
  strategy = "fixed",
  interactive = true,
  onVisibleChange
) {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip(
      {
        placement: position,
        interactive,
        delayHide: 100,
        trigger: clickType ? "click" : "hover",
        offset: [0, 2],
        onVisibleChange: (visibility) =>  onVisibleChange?.(visibility)
      },
      { strategy }
    )
  return {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  }
}
