const llmDesc =
  "State-of-the-art, open source large language models (LLMs) fine-tuned for analysis of free text customer feedback."
const records =
  "Number of records analyzed per month. A “record” is a single piece of user feedback upto 200 words."

export const ftPlans = {
  ft_records_trial: {
    plan_id: "ft_records_trial",
    name: "Trial",
    description: "7 day free trial.",
    cta: "Start Trial",
    plan_type: "trial",
    durations: {
      month: {
        price: 0,
        href: "/",
      },
      year: {
        price: 0,
        href: "/",
      },
    },
    quota_duration: "week",
    features: [
      {
        features: [
          {
            featureName: (
              <div>
                <b>Records:</b> 400 / month
              </div>
            ),
            featureDesc: records,
          },
          {
            featureName: (
              <div>
                <b>Channels:</b> CSV Upload
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>AI:</b> Large Language Models (LLMs)
              </div>
            ),
            featureDesc: llmDesc,
          },
          {
            featureName: (
              <div>
                <b>Language:</b> English
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>Support:</b> Email
              </div>
            ),
            featureDesc: null,
          },
        ],
      },
    ],
  },
  ft_starter_display_only: {
    plan_id: "ft_starter_display_only",
    name: "Starter",
    cta: "Book a demo",
    description: "Basics to get started.",
    durations: {
      month: {
        href: "/meet",
        price: 700,
      },
      year: {
        price: 7700,
        href: "/",
      },
    },
    features: [
      {
        features: [
          {
            featureName: (
              <div>
                <b>Records:</b> 5,000 / month
              </div>
            ),
            featureDesc: records,
          },
          {
            featureName: (
              <div>
                <b>Channels:</b> CSV Upload
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>AI:</b> Large Language Models (LLMs)
              </div>
            ),
            featureDesc: llmDesc,
          },
          {
            featureName: (
              <div>
                <b>Language:</b> English
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>Support:</b> Email
              </div>
            ),
            featureDesc: null,
          },
        ],
      },
    ],
  },
  ft_pro_display_only: {
    plan_id: "ft_pro_display_only",
    name: "Pro",
    description: "Somewhere in between.",
    cta: "Book a demo",
    durations: {
      month: {
        href: "/meet",
        price: 2100,
      },
      year: {
        price: 23100,
        href: "/",
      },
    },
    features: [
      {
        features: [
          {
            featureName: (
              <div>
                <b>Records:</b> 25,000 / month
              </div>
            ),
            featureDesc: records,
          },
          {
            featureName: (
              <div>
                <b>Channels:</b> CSV Upload + 2
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>AI:</b> Large Language Models (LLMs)
              </div>
            ),
            featureDesc: llmDesc,
          },
          {
            featureName: (
              <div>
                <b>Language:</b> Any 5 (English / EU)
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>Support:</b> Email, Chat
              </div>
            ),
            featureDesc: null,
          },
        ],
      },
    ],
  },
  ft_enterprise_display_only: {
    plan_id: "ft_enterprise_display_only",
    name: "Enterprise",
    description: "Scale up and track.",
    cta: "Book a demo",
    durations: {
      month: {
        href: "/meet",
        price: "Custom",
      },
      year: {
        href: "/meet",
        price: "Custom",
      },
    },
    features: [
      {
        features: [
          {
            featureName: (
              <div>
                <b>Records:</b> Custom
              </div>
            ),
            featureDesc: records,
          },
          {
            featureName: (
              <div>
                <b>Historical:</b> Included
              </div>
            ),
            featureDesc: null,
          },

          {
            featureName: (
              <div>
                <b>Channels:</b> Custom
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>Social:</b> Included
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>AI:</b> Large Language Models (LLMs)
              </div>
            ),
            featureDesc: llmDesc,
          },
          {
            featureName: (
              <div>
                <b>Language:</b> Any
              </div>
            ),
            featureDesc: null,
          },
          {
            featureName: (
              <div>
                <b>Support:</b> Dedicated Account Manager
              </div>
            ),
            featureDesc: null,
          },
        ],
      },
    ],
  },
}

export const ftPricingFaqs = {
  GENERAL: [
    {
      q: "What is a record?",
      a: "A record is a single piece of customer feedback. It could be a review, a customer complaint email, interaction with a chat agent, a support ticket, a verbatim response to a open ended survey question or even a voice conversation.",
    },
    {
      q: "What's CSV Upload?",
      a: "A CSV is a comma-separated values file, which allows data to be saved in a tabular format - like spreadsheet or excel sheets. You can export data from other systems and upload it onto FreeText AI as a CSV file for analysis. Examples of such data include - survey responses pulled from a survey tool, tickets exported from a CX or support platform, social media comments, etc.",
    },
    {
      q: "What's included in a free trial?",
      a: "You can upload and analyze upto 400 records in any language. The goal is let you experience the platform and it's capabilities on your data. Note - you will only see partial results of inferences.",
    },
    {
      q: "How accurate is the AI?",
      a: (
        <div>
          {" "}
          FreeText's AI is very accurate since it leverages state-of-the-art Large
          Language Models (LLMs), fine-tuned to perform tasks such as extracting
          granular, actionable topics and sentiment. LLMs bring a nuanced
          understanding of concepts and language and fine-tuning for specific tasks
          takes the accuracy beyond generally available LLMs such as ChatGPT.
          <br />
          <ul>
            Even LLMs can get it wrong sometimes, but the overall accuracy will be
            way higher than -<li> (1) manual tagging by service agents,</li>
            <li> (2) keyword rules based tagging tools,</li>
            <li>
              {" "}
              (3) other providers who use more generic AI models requiring custom
              training based on a pre-defined, rigid taxonomy.
            </li>
          </ul>
        </div>
      ),
    },
    {
      q: "What languages does FreeText support?",
      a: "FreeText AI supports all major languages out-of-the-box. The platform leverages state-of-the-art, multilingual Large Language Models (LLMs) to automatically analyze free text data in any language without translations or customizations.",
    },
    {
      q: "What is the typical contract duration?",
      a: "Contracts are typically on a monthly-rolling basis. If you're not getting value, you can opt-out anytime. However, if we build custom integrations or or custom models for you, then it would be an annual contract.",
    },
    {
      q: "Does FreeText integrate with my systems? How long does it take?",
      a: "Yes, custom integrations can be supported at an incremental cost. Typically, it can take ~2 weeks for a new integration.",
    },
  ],
}

export const ftPlanComparison = [
  {
    optionHeader: "Base",
    options: [
      {
        optionName: "Records per Month",
        optionDescription: records,
        optionValues: {
          ft_starter_display_only: "5,000",
          ft_pro_display_only: "25,000",
          ft_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Number of Channels",
        optionDescription:
          "The number of channels of data e.g Zendesk, Hubspot, etc.",
        optionValues: {
          ft_starter_display_only: (
            <div className="text-center">
              1 <br /> Upload CSV
            </div>
          ),
          ft_pro_display_only: (
            <div className="text-center">
              3 <br />
              Upload CSV + Survey / Support
            </div>
          ),
          ft_enterprise_display_only: "Custom",
        },
      },

      {
        optionName: "Social Channels",
        optionDescription: "Social Channels e.g Reddit, Twitter, YouTube, etc",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "1-Click Connectors",
        optionDescription: "Direct connectors with 1-click integration capability.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Languages",
        optionDescription:
          "Analysis in different languages and translation to English.",
        optionValues: {
          ft_starter_display_only: "English only",
          ft_pro_display_only: "Any 5 (English & EU)",
          ft_enterprise_display_only: "Any",
        },
      },
      {
        optionName: "Number of Users",
        optionDescription: "The number of users who can use the platform.",
        optionValues: {
          ft_starter_display_only: "Unlimited",
          ft_pro_display_only: "Unlimited",
          ft_enterprise_display_only: "Unlimited",
        },
      },
      {
        optionName: "Feedback / Text Analysis",
        optionDescription:
          "Extract recurring, granular topics, contextual sentiment, emotion, criticality, etc from free text feedback.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "AI: Large Language Models (LLMs)",
        optionDescription: llmDesc,
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },

      {
        optionName: "Analytics & Reporting",
        optionDescription:
          "Extensive reporting capabilities to create business relevant reports.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "ISO 27001, SOC 2 Type II",
        optionDescription: "Enterprise grade security and privacy practices.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Data Aggregation",
    options: [
      {
        optionName: "Historical Analysis",
        optionDescription: "Historical data sync and analysis.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Incremental Analysis",
        optionDescription:
          "Data will be automatically and incrementally aggregated from different channels and analyzed.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Connectors",
        optionDescription:
          "Custom Connectors for custom channels built at incremental cost.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Feedback Analysis",
    options: [
      {
        optionName: "Business Themes & Granular Topics",
        optionDescription:
          "Granular, actionable topics detected automatically from free text feedback.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Sentiment Classification",
        optionDescription:
          "Understand what users feel. Not just positive-negative, but classify feedback into questions and suggestions automatically.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Criticality Score",
        optionDescription:
          "Prioritize feedback based on criticality, not just sentiment.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Emotion Classification",
        optionDescription:
          "Understand the emotion behind feedback - satisfied, happy, angry, frustrated.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Large Language Models (LLMs)",
        optionDescription: llmDesc,
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Fine-Tuning LLMs",
        optionDescription:
          "Custom fine-tuning of the large language model based on your organization's data.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Themes (coming soon!)",
        optionDescription:
          "No more manual tagging and training. Just describe your theme and let the machines do the rest.",
        optionValues: {
          ft_starter_display_only: "coming soon!",
          ft_pro_display_only: "coming soon!",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Theme Summaries (coming soon!)",
        optionDescription: "AI generated summaries for each theme or topic.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Voice Call Analysis (coming soon!)",
        optionDescription:
          "Voice calls will be transcribed using best-in-class APIs and analyzed.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
    ],
  },
  {
    optionHeader: "Analytics & Reporting",
    options: [
      {
        optionName: "Impact on Primary Metric",
        optionDescription:
          "Impact of any theme on the primary business metric e.g CSAT, NPS automatically calculated.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Dynamic Reports & Dashboards",
        optionDescription:
          "Search, Sort & Filter by various meta data to generate different cuts of the reports in real time.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Report & Widget Builder",
        optionDescription: "Build custom widgets and reports for your business!",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Advanced Search",
        optionDescription: "AI assisted feedback search feature.",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
    ],
  },

  {
    optionHeader: "Workflows",
    options: [
      {
        optionName: "Scheduled Reports (coming soon!)",
        optionDescription:
          "Get useful summaries of data & insights sent via email daily, weekly or monthly.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Alerts (coming soon!)",
        optionDescription:
          "Get instantly alerted when a new review is received, if the star rating drops, etc.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Routing Rules / Automation (coming soon!)",
        optionDescription:
          "Set up automation rules in your ticketing system based on topic/sentiment tags.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
    ],
  },
  {
    optionHeader: "Export",
    options: [
      {
        optionName: "Export to CSV & PPTX",
        optionDescription: "Export data in CSV or PPTX format",
        optionValues: {
          ft_starter_display_only: "yes",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "API Access (coming soon!)",
        optionDescription: "Export data programmatically via a RESTful JSON API",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
    ],
  },
  {
    optionHeader: "Customer Success & Support",
    options: [
      {
        optionName: "Onboarding Sessions",
        optionDescription:
          "Product experts will help onboard you to the FreeText platform.",
        optionValues: {
          ft_starter_display_only: "2 sessions",
          ft_pro_display_only: "4 sessions",
          ft_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Ongoing Support",
        optionDescription:
          "Support via email, chat or dedicated account managers to help with customizations.",
        optionValues: {
          ft_starter_display_only: "Email",
          ft_pro_display_only: "Email & Chat",
          ft_enterprise_display_only: "Dedicated Account Manager",
        },
      },
      {
        optionName: "Roadmap Influence",
        optionDescription:
          "Influence the feature roadmap e.g get your requests prioritized.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Account, Security & Billing",
    options: [
      {
        optionName: "Procurement Support",
        optionDescription:
          "Procurement documentation eg. vendor or security onboarding questionnaires",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Invoice Billing",
        optionDescription: "Pay via invoice rather than credit card",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "yes",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Contracts",
        optionDescription: "Negotiate terms and draw up a custom contract.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Single Sign-On (SSO) (Coming Soon!)",
        optionDescription:
          "Integrate FreeText with your SAML 2.0 compatible SSO provider.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
      {
        optionName: "Multiple Workspaces (Coming Soon!)",
        optionDescription:
          "Multi workspace support for separating out data between different projects.",
        optionValues: {
          ft_starter_display_only: "no",
          ft_pro_display_only: "no",
          ft_enterprise_display_only: "coming soon!",
        },
      },
    ],
  },
]
