export const currentsFaqs = {
  General: [
    {
      q: "What is Currents AI?",
      a: (
        <div>
          We understand how critical it is for businesses like yours to make
          sense of customer feedback, understand pain points and iterate. That's
          why we built Currents AI - to help you analyze infinite amounts of
          textual customer feedback from various sources in minutes.{" "}
          <div>
            Our platform leverages cutting edge Machine Learning, Natural
            Language Processing and Neural Networks to extract meaning and
            insights from unstructured textual feedback data such as user
            reviews, chats, surveys, support tickets, etc.
          </div>
        </div>
      ),
    },
    {
      q: "Who can use Currents AI?",
      a: "If you are a business that has significant (and growing) textual customer feedback in the form of reviews, surveys, chats, emails or support tickets, then you can use the Currents AI platform to help understand patterns and insights from your data. This tool is especially useful for Customer Experience Owners, Marketers, Product Managers, Research Analysts, even CEOs and Founders.",
    },
    {
      q: "How can I get started?",
      a: (
        <div>
          Simply create an account for free and connect your data sources to the
          Currents AI platform in a few clicks. Once the data sources are
          connected, our machines will fetch and analyze your data instantly.
        </div>
      ),
    },
  ],
  "Data Integrations": [
    {
      q: "What types of data can you analyze?",
      a: "All types of textual data can be analysed for insights and patterns - user reviews, customer emails, support tickets, chat conversations, user comments, social media posts, etc.",
    },
    {
      q: "What data platforms or integrations do you support?",
      a: (
        <div>
          We are continueously adding support for new sources. Currently, the
          following sources are supported -
          <div>
            Reviews&nbsp; -&nbsp; Play Store, Amazon, Best Buy, Flipkart, Steam,
            Metacritic, etc{" "}
          </div>
          <div>
            Chats &nbsp;&nbsp;&nbsp;&nbsp; -&nbsp; Intercom, JivoChat, Drift
          </div>
          <div>
            If you want us to add support for your specific source, get in touch{" "}
            <a className="link-blue" href="/contact">
              here
            </a>
            .
          </div>
        </div>
      ),
    },
    {
      q: "Is my internal data secure?",
      a: "Absolutely. Currents AI, at its core, is a data company and protecting your data is our primary concern. All the data is stored on secure infrastructure and necessary best-practices are followed to completely secure the data.",
    },
    {
      q: "I just connected my data - how long will it take for the analysis to appear?",
      a: (
        <div>
          The data sync and analysis process can take anything between a few
          minutes to a few hours, depending on a number of factors - number of
          data sources, type of data, vertical of business, volume of data, etc.{" "}
          <div>
            Typically, the dashboard will reflect an accurate progress status.
            We will also send you an email at your registered email id as soon
            as the analysis is completed.
          </div>
        </div>
      ),
    },
    {
      q: "Does Currents AI support languages other than English?",
      a: (
        <div>
          Yes, we can analyse feedback across most languages.{" "}
          <a className="link-blue" href="/contact">
            Contact us
          </a>{" "}
          for a custom quote.
        </div>
      ),
    },
    {
      q: "I have data in a source not supported by you. What can I do?",
      a: (
        <div>
          No issues. Simply{" "}
          <a className="link-blue" href="/contact">
            {" "}
            write to us
          </a>{" "}
          with the details of the new source or platform and in most cases, we
          will either be able to provide an integration option or a workaround.
        </div>
      ),
    },
  ],
  Pricing: [
    {
      q: "What does it cost to use Currents AI?",
      a: (
        <div>
          The pricing varies based on a few factors - number of data sources,
          volume of data to be analyzed, type of data, etc.{" "}
          <a className="link-blue" href="/contact">
            Contact us
          </a>{" "}
          for a custom quote.
        </div>
      ),
    },
  ],
  Troubleshooting: [
    {
      q: "Why is my analysis not completed yet?",
      a: (
        <div>
          The analysis can take anything between a few minutes to a few hours,
          depending on a number of factors - number of data sources, type of
          data, vertical of business, volume of data, etc.{" "}
          <div>
            Typically, the dashboard will reflect an accurate progress status.
            We will also send you an email at your registered email id as soon
            as the analysis is completed.
          </div>
          <div>
            However, if for any reason, you suspect unreasonable delay, do reach
            out and we'll take a look immediately.
          </div>
        </div>
      ),
    },
    {
      q: "I noticed some discrepencies in the data quality? What do I do?",
      a: (
        <div>
          <div>
            We leverage custom Neural Networks to detect topics of discussion
            and sentiment in feedback sentences and constantly monitor the
            quality of algorithms to maximize accuracy and ensure that outliers
            do not influence the eventual conclusions. However, it is still
            possible that there will be a few cases of incorrect classification.
          </div>{" "}
          <div>
            If you discover any large discrepencies or quality issues, we
            encourage you to provide feedback using the "Report Wrong Tag" link
            provided in the menu against each sentence or{" "}
            <a className="link-blue" href="/contact">
              contact us
            </a>{" "}
            with the details. We will take these inputs into consideration while
            tweaking our algorithms.
          </div>{" "}
        </div>
      ),
    },
  ],
}
