import Button from "@common/buttons/button"
import { Header } from "@common/header/headerUnit"
import { THEME } from "configs/theme"
import Link from "next/link"
import { useState, useEffect } from "react"
import { useCookies } from "react-cookie"

export function useCookieManager() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "freetext_auth_token",
    "g_state",
    "cookie_consent",
  ])
  const [showConsentModal, setShowConsentModal] = useState(false)

  useEffect(() => {
    const isEuDomain = window.location.hostname === "eu.freetext.ai"

    // If the domain is not eu.freetext.ai, automatically accept cookies
    if (!isEuDomain) {
      // Check if the cookie consent has not been set yet
      if (cookies.cookie_consent === undefined) {
        setCookie("cookie_consent", true, { path: "/" })
      }
      // Ensure the modal doesn't show
      setShowConsentModal(false)
    } else if (cookies.cookie_consent === undefined) {
      // If the domain is eu.freetext.ai and the consent cookie is undefined, show the modal
      setShowConsentModal(true)
    }
  }, [cookies, setCookie])

  const handleAccept = () => {
    setCookie("cookie_consent", true, { path: "/" })
    setCookie("g_state", { path: "/" })
    setShowConsentModal(false)
  }

  const handleReject = () => {
    setCookie("cookie_consent", false, { path: "/" })
    removeCookie("g_state")
    setShowConsentModal(false)
  }

  const CookieConsentModal = () => {
    return showConsentModal ? (
      <div className="bg-background rounded-md border  fixed bottom-0 left-0 mb-4 ml-4 p-4 z-50 max-w-lg">
        <Header>
          <Header.MainHeader>{`${THEME.siteShort} uses cookies`}</Header.MainHeader>
          <Header.Description>
            <div className="gap-4 flex flex-col">
              <p>
                We would like to use our own and third party cookies and similar
                technology for
              </p>
              <ul>
                <li>&#8226; necessary purposes</li>
                <li>&#8226; analytical purposes</li>
              </ul>
              <p>
                You can set your preferences by selecting the options below. Withdraw
                your conesent at any time by mailing us at{" "}
                <span className="font-medium">{THEME.contactEmail}</span>
              </p>
              <Link className="text-accent underline" href={"#"}>
                Learn more about how we use cookies
              </Link>
              <div className="flex justify-end items-center gap-2">
                <Button variant={"outline"} onClick={handleReject}>
                  Reject
                </Button>
                <Button variant={"accent"} onClick={handleAccept}>
                  Accept
                </Button>
              </div>
            </div>
          </Header.Description>
        </Header>
      </div>
    ) : null
  }

  return {
    CookieConsentModal,
  }
}
