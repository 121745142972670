import * as React from "react"
import ContentOnHover from "./contentOnHover"

/**
 * 
 * @param {JSX} children -->  Tooltip.Trigger SHOULD BE THE FIRST ELEMENT
 * @param {string} className -->  WRAPS AROUND THE CONTENT BOX
 * @param {string} triggerClass -->  WRAPS AROUND THE TRIGGER BOX
 * @param {Boolean} isEnabled -->  FALSE DISABLES TOOLTIP FUNCTIONING
 * @param {Boolean} hasArrow -->  RENDERS THE POINTER TO TOOLTIP BOX
 * @param {Boolean} position
 * @param {string} portalClass 
 * @param {Boolean} isDarkModeResponsive
 * @param {Boolean} isDarkMode
 * 
 * @returns {JSX} --> TOOLTIP COMPONENT
 * 
 * @example
 * <Tooltip>
      <Tooltip.Trigger>
        <div className="text-md text-gray-500">{renderIcon()}</div>
      </Tooltip.Trigger>
      <Tooltip.Content>{children}</Tooltip.Content>
    </Tooltip>
 */
function Tooltip({
  children,
  className,
  triggerClass,
  isEnabled = true,
  hasArrow = true,
  position,
  isMaxContent = false,
  strategy,
  isInteractive,
}) {
  return (
    <ContentOnHover
      className={
        className ||
        "p-2 text-xs shadow-elevate1 shadow-zinc-200 dark:shadow-zinc-800 break-words "
      }
      triggerClass={triggerClass}
      isEnabled={isEnabled}
      hasArrow={hasArrow}
      position={position}
      isMaxContent={isMaxContent}
      strategy={strategy}
      isInteractive={isInteractive}
    >
      {children}
    </ContentOnHover>
  )
}

const Trigger = ({ children }) => {
  return <>{children}</>
}

const Content = ({ children }) => {
  return <>{children}</>
}
Tooltip.Trigger = Trigger
Tooltip.Content = Content

export default Tooltip
