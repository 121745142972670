import { get, getSSR, getSwr, post, postSwr } from "network/networkInterface.js"
import { getApiURL, getHashFromObject } from "lib/util"
import { removeQueryParamsFromQuerystring } from "@common/lib/navigation"
import {
  autoRefreshSWROptions,
  getFullUrl,
  handleError,
  options,
  postFetcher,
} from "network/utils"
import { appendQuery } from "lib/filters"

export function getBookmarks() {
  const { data, isLoading, isError, mutate, message, status } =
    getSwr(`api_v2/bookmarks`)
  return {
    bookmarks: data,
    isBookmarksLoading: isLoading,
    isBookmarksError: isError,
    mutate,
    message,
    status,
  }
}

export const downloadReport = async (apiUrl, details, filename, onResponse) => {
  const url = getFullUrl(apiUrl)
  const detailsStr = JSON.stringify({
    ...details,
    query: details.query || "",
  })

  try {
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: detailsStr,
    })
      .then(function (resp) {
        return resp.blob()
      })
      .then(function (blob) {
        //return download(blob, "CUSTOM_NAME.pdf")
        onResponse()
        const href = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = href
        link.setAttribute("download", filename || "download.csv") //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  } catch {
    alert("Could not download file. Please try again.")
    onResponse()
  }
}

export async function uploadFile(url, formData) {
  try {
    return await postFetcher(url, formData, {})
  } catch (error) {
    return handleError(error, `uploadFile ${url}`)
  }
}

export function getCatalogTask({ customerId }) {
  const url = `api_v2/get_catalog_task_status/${customerId}`
  return getSwr(url)
}

export function getQuota() {
  const shouldFetch = true
  const { data, isLoading, isError, message, status } = getSwr(
    `api_v2/get_quota`,
    shouldFetch
  )
  return {
    quota: data,
    isQuotaLoading: isLoading,
    isQuotaError: isError,
    message,
    status,
  }
}

export function getSources(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `api_v2/get_sources/${customerId}`,
    shouldFetch
  )
  return {
    sources: data,
    isSourcesLoading: isLoading,
    isSourcesError: isError,
    message,
    status,
  }
}

export function getCategories(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `api_v2/get_categories/${customerId}`,
    shouldFetch
  )
  return {
    categories: data,
    isCategoriesLoading: isLoading,
    isCategoriesError: isError,
    message,
    status,
  }
}

export const resendOtp = async () => {
  return await get(`api_v2/resend_otp`)
}

export const verifyOtp = async (details) => {
  return await post(`api_v2/verify_otp`, details)
}

export function getPermissions(shouldFetch = true) {
  const { data, isLoading, isError, mutate, message, status } = getSwr(
    `api_v2/get_permission`,
    shouldFetch
  )

  return {
    permissions: data,
    isPermissionsLoading: isLoading,
    isPermissionsError: isError,
    mutate,
    message,
    status,
  }
}

// payments
export function getPaymentPlans(details, shouldFetch = true) {
  return postSwr(`api_v2/get_plans_meta`, details, shouldFetch)
}

// account preferences
export const getAccountPreferences = (customerId) => {
  if (!customerId) return Promise.reject({ error: "Customer not found" })
  const apiUrl = `api_v2/get_account_prefs/${customerId}`
  return get(apiUrl)
}

// customer preferences
export const getCustomerPreferences = (customerId) => {
  const apiUrl = `api_v2/get_customer_prefs/${customerId}`
  return get(apiUrl)
}

export const updateCustomerPreferences = async (customerId, details) => {
  return await post(`api_v2/update_customer_prefs/${customerId}`, details)
}

export const updateAccountPreferences = async (customerId, details) => {
  return await post(`api_v2/update_account_prefs/${customerId}`, details)
}

export function getAccountPreferencesSSR(cookie, customerId) {
  const apiUrl = `api_v2/get_account_prefs/${customerId}`
  return getSSR(cookie, apiUrl)
}

// same as fetchEntityList without SWR
export async function getEntityList(
  customerId,
  ids,
  catId,
  sourceId,
  reportId,
  numResults = 100,
  text,
  type,
  expand = false
) {
  const apiUrl = getApiURL({
    customerId,
    type,
    ids,
    catId,
    text,
    numResults,
    sourceId,
    reportId,
    expand,
  })
  const { data, status, message } = await get(apiUrl)
  return {
    entities: data,
    message,
    status,
  }
}

// tasks
export const createUserTask = async (details) => {
  return post(`api_v2/data_source/user_task`, details)
}

export const deleteUserTask = async (details) => {
  return post(`api_v2/delete_user_task`, details)
}

export const refreshUserTask = async (details) => {
  return post(`api_v2/data_source/user_task/recrawl`, details)
}

//CREATES BOOKMARK
export const createOrUpdateBookmark = async (details) => {
  return post(`api_v2/update_bookmarks`, details)
}

//DELETES BOOKMARK
export const deleteBookmark = async (bookmark_id) => {
  return get(`api_v2/delete_bookmark/${bookmark_id}`)
}

// forceFetch true -> fetches once. Else fetches every 10 seconds
export function getUserTasksSwr(
  customerId,
  searchQuery,
  pageSize,
  start,
  shouldFetch,
  forceFetch = false
) {
  let apiUrl = `api_v2/get_user_tasks/${customerId}`
  if (pageSize) apiUrl += `?count=${pageSize}`
  if (start != null)
    apiUrl += `${apiUrl.includes("?") ? `&start=${start}` : `?start=${start}`}`
  if (searchQuery) apiUrl += `&text=${searchQuery}`

  const extraOptions = forceFetch == true ? options : autoRefreshSWROptions
  return getSwr(apiUrl, shouldFetch, null, forceFetch, extraOptions)
}

export function getUserTasks(customerId) {
  const apiUrl = `api_v2/get_user_tasks/${customerId}`
  return get(apiUrl)
}

export function getUserTasksDetails(customerId, userTaskId, shouldFetch) {
  const apiUrl = `api_v2/get_user_tasks/${customerId}?utid=${userTaskId}`
  return getSwr(apiUrl, shouldFetch)
}

// group mangement
export function deleteTag(customerId, categoryId, tagId, shouldDelete) {
  const shouldFetch =
    customerId && categoryId && tagId && shouldDelete == true ? true : false
  return getSwr(
    `api_v2/delete_tag/${customerId}/${categoryId}/${tagId}`,
    shouldFetch
  )
}
export const updateTag = async (details) => {
  return post(`api_v2/update_tag`, details)
}

/**
 *
 * TOPIC
 */

// GET SYSTEM TOPICS

export const getSystemTopics = (customerId, categoryId) => {
  const apiUrl = `api_v2/get_system_topics/${customerId}/${categoryId}`
  const shouldFetch = customerId && categoryId
  return getSwr(apiUrl, shouldFetch)
}

// GET USER DEFINED TOPICS
export const getUserDefinedTopics = (customerId, categoryId) => {
  const apiUrl = `api_v2/get_user_defined_topics/${customerId}/${categoryId}`
  const shouldFetch = !!customerId && !!categoryId
  return getSwr(apiUrl, shouldFetch)
}

// UPDATE SYSTEM TOPIC
export const updateSystemTopics = async (customerId, categoryId, details) => {
  const apiUrl = `api_v2/update_system_topic/${customerId}/${categoryId}`
  return post(apiUrl, details)
}

// CREATE OR UPDATE TOPIC
export const updateUserDefinedTopic = async (customerId, categoryId, details) => {
  const apiUrl = `api_v2/update_user_defined_topic/${customerId}/${categoryId}`
  return post(apiUrl, details)
}

export const deleteUserDefinedTopic = async (customerId, categoryId, topicId) => {
  const apiUrl = `api_v2/delete_user_defined_topic/${customerId}/${categoryId}/${topicId}`
  return get(apiUrl)
}

export function getTopicTerms(customerId, categoryId, filters) {
  const apiUrl = `api_v2/query/${customerId}/${categoryId}/sub_aspect`

  // senti is being removed, because backend uses it to filter down further (and the overall counts do not match.). If we were to move these tags to RHS under the senti tabs, then sending sentiment would be right.
  const newFilters = removeQueryParamsFromQuerystring(filters, "senti")

  const details = {
    cust_id: customerId,
    cat_id: categoryId,
    query: newFilters,
    query_type: "sub_aspect",
  }
  return post(apiUrl, details)
}

// serves as an eg. of how to use postSwr with mutate
export function updateTagSwr(details, shouldFetch, shouldMutate) {
  const { data, isLoading, isError, message, status } = postSwr(
    `api_v2/update_tag`,
    details,
    shouldFetch,
    shouldMutate,
    false
  )
  return {
    updateData: data,
    isUpdateLoading: isLoading,
    isUpdateError: isError,
    message,
    status,
  }
}

// responses
export function updateCustomerTodo(customerId, details) {
  return post(`api_v2/update_cust_todo/${customerId}`, details)
}

export function updateAccountDetails(details) {
  return post(`api_v2/account_details`, details)
}

export function getAccountDetails() {
  return getSwr("api_v2/account_details", true)
}

export function inviteUsers(details) {
  const apiUrl = "api_v2/invite_users"
  return post(apiUrl, details)
}
export function getTeamAccounts() {
  return getSwr("api_v2/get_team_accounts")
}

export function getTeams() {
  return getSwr("api_v2/get_teams")
}

export function updateTeam(details) {
  const apiUrl = "api_v2/update_team"
  return post(apiUrl, details)
}

export function updateUserResource(details) {
  const apiUrl = `api_v2/resource/update_user`
  return post(apiUrl, details)
}

export function deleteUser(account_id) {
  const apiUrl = `api_v2/resource/delete_user`
  return post(apiUrl, { account_id })
}
export function deleteTeam(teamId) {
  const apiUrl = `api_v2/teams/delete_team/${teamId}`
  return get(apiUrl)
}
// account login/signup/isLoggedIn
export const logout = async () => {
  const apiUrl = `api_v2/logout`
  const url = getFullUrl(apiUrl)
  try {
    const response = await fetch(url)
    const resCode = response.status
    const success = resCode == 200 ? true : false
    if (resCode == 200)
      return {
        success: success,
        isError: null,
      }
  } catch (error) {
    return {
      success: false,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const getLogin = async (details) => {
  return post(`api_v2/login`, details)
}

export const getQuickLogin = async (details) => {
  return post(`api_v2/login/magic_link`, details)
}

//? ******* DO NOT USE GET, FETCHER HERE ,THIS IS INVOKED IN SERVER SIDE SO WE DON'T WANT TO REDIRECT *******
export const getIsLoggedIn = async (cookie, query) => {
  const apiUrl = `api_v2/is_logged_in`
  let url = getFullUrl(apiUrl)
  if (query) url += `?${query}`
  try {
    const response = await fetch(url, cookie)
    const data = await response.json()
    const resCode = response.status
    return {
      data: data,
      resCode: resCode,
      isError: null,
    }
  } catch (error) {
    return {
      data: null,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export const useIsLoggedIn = (publicId) => {
  const apiUrl = `api_v2/is_logged_in?public_id=${publicId}`
  return getSwr(apiUrl)
}

export const getSignup = async (details) => {
  return post(`api_v2/register`, details)
}

export const getResetPassword = async (details) => {
  return post(`api_v2/update_password`, details)
}

export const getResetPasswordToken = async (details) => {
  return post(`api_v2/reset_pass_send_token`, details)
}
/* ONTOLOGY */
export function getOntologies(customerId) {
  const shouldFetch = customerId ? true : false
  const { data, isLoading, isError, message, status } = getSwr(
    `api_v2/get_ontologies/${customerId}`,
    shouldFetch
  )
  return {
    ontologies: data,
    isOntologiesLoading: isLoading,
    isOntologiesError: isError,
    message,
    status,
  }
}

/* COMMON */
// TODO - move to network/common.js

// TODO - convert all repeated get/post calls to use these common functions
// apiUrl e.g `api_v2/update_account_prefs/${customerId}`

export async function gLogin(details) {
  return post(`api_v2/google_oauth_redirect`, details)
}

// <---- FOR TRI MODE START ---->

export function getCategoryList() {
  return get(
    `https://storage.googleapis.com/thereviewindex-generalindex-views/categories.json`,
    true
  )
}

export function getListsList() {
  return get(
    `https://storage.googleapis.com/thereviewindex-generalindex-views/lists.json`,
    true
  )
}
// <---- FOR TRI MODE END ---->

/**
 *
 * *********************UPLOAD CSV******************
 */
export function useLabels(shouldFetch) {
  return getSwr(`api_v2/get_annotated_labels`, shouldFetch)
}

export function updateCustomerLabel(details) {
  const apiUrl = `api_v2/update_annotated_labels`
  return post(apiUrl, details)
}

export function validateCSV(details) {
  const apiUrl = `api_v2/data_source/validate_csv`
  return post(apiUrl, details)
}

/**
 *
 *********************** REPORT ***************
 */

export function getReports(
  reportId,
  categoryId,
  entityId,
  reportType, //system_report or user_report
  reportDefClass, //available or showcase
  reportMode, // template or instance
  shouldFetch = true
) {
  let apiUrl = `api_v2/reports`
  const params = new URLSearchParams()

  // Append parameters if they exist
  if (categoryId && entityId) {
    params.append("cat_id", categoryId)
    params.append("entity_id", entityId)
  }
  if (reportId) params.append("report_id", reportId)
  if (reportType) params.append("report_type", reportType)
  if (reportDefClass) params.append("report_def_class", reportDefClass)
  if (reportMode) params.append("report_mode", reportMode)

  // Only append the query string if there are parameters
  if (Array.from(params).length > 0) {
    apiUrl += `?${params}`
  }

  return getSwr(apiUrl, shouldFetch)
}

export function getReport(reportId) {
  const shouldFetch = reportId != undefined
  return getReports(reportId, null, null, null, null, null, shouldFetch)
}

export function createReport(details) {
  const apiUrl = `api_v2/reports/create_report`
  return post(apiUrl, details)
}

export function deleteReport(reportId) {
  const apiUrl = `api_v2/reports/delete_report?report_id=${reportId}`
  return get(apiUrl)
}

export function updateReport(details) {
  const apiUrl = `api_v2/reports/update_report`
  return post(apiUrl, details)
}

/**
 *
 *********************** WIDGET ***************
 */
export function useFetchWidgets(widgetIds = [], isEditable = true) {
  const ids = widgetIds.join(",")
  let apiUrl = `api_v2/get_widgets?only_editable=${isEditable}`
  if (ids) apiUrl += `&widget_ids=${ids}`
  return getSwr(apiUrl)
}

export function getWidget(widgetId, isEditable = true) {
  const apiUrl = `api_v2/get_widgets?widget_ids=${widgetId}&only_editable=${isEditable}`
  return get(apiUrl)
}

export function useFetchWidget(widgetId, isEditable = true, shouldFetch) {
  const apiUrl = `api_v2/get_widgets?widget_ids=${widgetId}&only_editable=${isEditable}`
  return getSwr(apiUrl, shouldFetch)
}

export function updateWidget(details = {}) {
  const apiUrl = "api_v2/update_widget"
  return post(apiUrl, details)
}

export function deleteWidget(widgetId) {
  const apiUrl = `api_v2/delete_widget/${widgetId}`
  return get(apiUrl)
}

export function useFetchMetricsDims(details = {}, shouldFetch = false) {
  const detailsHash = getHashFromObject(details)
  const apiUrl = `api_v2/get_metrics_dims/${detailsHash}`
  return postSwr(apiUrl, details, shouldFetch)
}

export function updateRecord(details, cat_id) {
  return post(`api_v2/${cat_id}/records`, details)
}

/**************** PHRASES GENERATION**********************/
export function generatePhrases(details) {
  return post(`api_v2/user_topics/generate_suggested_phrases`, details)
}

export function getPreviewPhrases(details) {
  return post(`api_v2/user_topics/phrases/preview`, details)
}

export function generateSimilarPhrases(details) {
  return post("api_v2/user_topics/generate_similar_phrases", details)
}
