import { ReactNode } from "react"
import { joinClassNames } from "../lib/util"

interface CardProps {
  className?: string
  noPadding?: boolean
  dataPresentation?: any
  children: ReactNode
}

export default function Card({
  noPadding = false,
  className = "",
  children,
}: CardProps) {
  // card needs to be wrapped in "relative" otherwise tooltip content gets bound by the card
  return (
    <div
      className={joinClassNames(
        "relative w-full h-full border  rounded-md overflow-auto ",
        noPadding === true ? "" : "px-4 py-5 sm:p-5",
        className
      )}
    >
      {children}
    </div>
  )
}
