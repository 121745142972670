import Loader from "@common/loader"
import { useRouter } from "next/router"
import CircularProgressBar from "@common/circularProgressBar"
import { getDateStrFromEpoch } from "@common/datePickers/utils"
import useQuota from "components/quota/useQuota"
import React from "react"
import Card from "@common/cards/card"
import Progress from "@common/progress"
import { isNullOrEmpty } from "@common/lib/util"
import Button from "@common/buttons/button"
import { getManagePlansUrl } from "lib/navigation"

interface QuotaProps {
  isVerticalUnit: boolean
  showManageButton: boolean
}

interface QuotaInfoProps {
  label: string
  value: any
}

export default function Quota({
  isVerticalUnit = false,
  showManageButton = false,
}: QuotaProps) {
  const router = useRouter()
  const { quota, total, consumed, percentage, pending, expiry, isDarkMode } =
    useQuota()

  if (!quota) return <Loader />
  if (isNullOrEmpty(quota) || !quota.plan_id) return null

  // TODO - responsive layouting css can be used instead of if-else.
  if (isVerticalUnit)
    return (
      <div className="max-w-md">
        <Card>
          <div className="flex flex-col divide-y divide-primary">
            <div className=" text-center py-6">{renderPlan()}</div>
            <div className="py-6">
              <div className="w-28 mb-6 m-auto">{renderCircularProgress()}</div>
              {renderDetails()}
            </div>
            {showManageButton && (
              <div className="text-center pt-6">{renderManageButton()}</div>
            )}
          </div>
        </Card>
      </div>
    )

  return (
    <Card>
      <div className="grid grid-cols-4 divide-x divide-primary">
        <div className="text-center">{renderPlan()}</div>
        <div className=" col-span-2 flex">
          <div className="m-auto flex">
            <div className="h-32 w-32">{renderCircularProgress()}</div>
            {renderDetails()}
          </div>
        </div>
        {showManageButton && (
          <div className="flex items-center ">
            <div className="m-auto">{renderManageButton()}</div>
          </div>
        )}
      </div>
    </Card>
  )

  function renderManageButton() {
    return (
      <a href={getManagePlansUrl(router.asPath)} target="_blank">
        <Button variant="primary">Manage</Button>
      </a>
    )
  }

  function renderPlan() {
    return (
      <div className="flex w-full h-full items-center">
        <div className="m-auto">
          <div className="header-gray mb-1">Current Plan</div>
          <div className="mb-1 header-3">{quota.plan_name}</div>
        </div>
      </div>
    )
  }

  function renderCircularProgress() {
    return <CircularProgressBar percentage={percentage} isDarkMode={isDarkMode} />
  }

  function renderDetails() {
    return (
      <div className="ml-8 grid grid-cols-2 gap-2 text-sm">
        <QuotaInfo label="Total Monthly Quota" value={total} />
        <QuotaInfo label="Quota Used" value={consumed} />
        <QuotaInfo label="Quota Pending" value={pending} />
        <QuotaInfo label="Quota Expire On" value={getDateStrFromEpoch(expiry)} />
      </div>
    )
  }
}

function QuotaInfo({ label, value }: QuotaInfoProps) {
  return (
    <React.Fragment key={label}>
      <div className="text-gray-600 font-medium tracking-wider dark:text-gray-500 float-right">
        <div>{label}</div>
      </div>
      <div className="text-black dark:text-white">
        <div>{value}</div>
      </div>
    </React.Fragment>
  )
}

export function QuotaSmall() {
  const { quota, consumed, total } = useQuota()

  if (!quota) return <Loader />
  if (isNullOrEmpty(quota)) return null

  return (
    <Progress
      label={"Used"}
      completed={consumed}
      total={total}
      showCount={undefined}
      theme="gray"
    />
  )
}
