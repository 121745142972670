module.exports = {
  border: "var(--border)",
  input: "var(--input)",
  background: "var(--background)",
  foreground: "var(--foreground)",
  primary: { // black ish
    DEFAULT: "var(--primary)",
    foreground: "var(--primary-foreground)",
  },
  secondary: { // blue ish
    DEFAULT: "var(--secondary)",
    foreground: "var(--secondary-foreground)",
  },
  destructive: {
    DEFAULT: "var(--destructive)",
    foreground: "var(--destructive-foreground)",
  },
  muted: {
    DEFAULT: "var(--muted)",
    foreground: "var(--muted-foreground)",
  },
  accent: {
    DEFAULT: "var(--accent)",
    foreground: "var(--accent-foreground)",
  },
  popover: {
    DEFAULT: "var(--popover)",
    foreground: "var(--popover-foreground)",
  },
  card: {
    DEFAULT: "var(--card)",
    foreground: "var(--card-foreground)",
  },
  warning: {
    DEFAULT: "var(--warning)",
    foreground: "var(--warning-foreground)",
  },
  // theme: { // remove
  //   DEFAULT: "var(--theme)",
  //   foreground: "var(--theme-foreground)",
  // },
}
