/*
  - This file will contain functions that manage filter transformations at the application level (beyond the filters component). 
  - Functions that are used by the filters component will reside in components/filters/util.tsx
  - filters.js will be deprecated.
*/

import { getFiltersStateFromFilters } from "lib/filters"
import { OnSelect } from "lib/types"
import { FiltersState } from "components/filters/types"

// TODO - filtersConfig: FiltersConfig
export function getFiltersStateFromOnSelect(
  path: string,
  onSelect: OnSelect,
  filtersConfig: any
) {
  const filtersState: FiltersState = {}

  if (!onSelect || !("filters" in onSelect)) return filtersState

  const filters = []
  for (const filterKey in onSelect["filters"]) {
    const filterValue = onSelect["filters"][filterKey]
    filters.push(`${filterKey}=${filterValue}`)
  }

  // include all the keys sent by backend, irrespective of whether or not they are in filters
  return {
    //...getFiltersStateFromUrl(path, filtersConfig),
    ...getFiltersStateFromFilters(filters, filtersConfig),
    ...getFiltersStateFromFilters(filters, filtersConfig, false, true),
  }
}
