export const triPlans = {
  tri_reviews_trial: {
    plan_id: "tri_reviews_trial",
    name: "Trial",
    description: "7 day free trial.",
    cta: "Buy Trial",
    plan_type: "trial",
    durations: {
      month: {
        price: 0,
        href: "/",
      },
      year: {
        price: 0,
        href: "/",
      },
    },
    quota_duration: "week",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "3 products / month",
            featureDesc: null,
          },
          {
            featureName: "1 eCommerce channel",
            featureDesc: null,
          },
          {
            featureName: "1 User",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "FEATURES",
        features: [
          {
            featureName: "Product & Variant Reports",
            featureDesc: null,
          },
          {
            featureName: "Topics & Sentiment Reports",
            featureDesc: null,
          },
          {
            featureName: "Intelligent Review Search",
            featureDesc: null,
          },
          {
            featureName: "Comparison & Benchmarking",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  tri_reviews_small: {
    plan_id: "tri_reviews_small",
    name: "Small",
    description: "Basics to get started.",
    cta: "Buy Small",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "100 products / month",
            featureDesc: null,
          },
          {
            featureName: "1 eCommerce channel",
            featureDesc: null,
          },
          {
            featureName: "300 translations / month",
            featureDesc: null,
          },
          {
            featureName: "1 User",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "FEATURES",
        features: [
          {
            featureName: "Product & Variant Reports",
            featureDesc: null,
          },
          {
            featureName: "Topics & Sentiment Reports",
            featureDesc: null,
          },
          {
            featureName: "Intelligent Review Search",
            featureDesc: null,
          },
          {
            featureName: "Comparison & Benchmarking",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  tri_reviews_medium: {
    plan_id: "tri_reviews_medium",
    name: "Medium",
    description: "Somewhere in between.",
    cta: "Buy Medium",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "1000 products / month",
            featureDesc: null,
          },
          {
            featureName: "7 eCommerce channels",
            featureDesc: null,
          },
          {
            featureName: "1000 translations / month",
            featureDesc: null,
          },
          {
            featureName: "3 Users",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "← PLUS",
        features: [
          {
            featureName: "Brand Analysis Reports",
            featureDesc: null,
          },
          {
            featureName: "Custom Group Reports",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  tri_reviews_large: {
    plan_id: "tri_reviews_large",
    name: "Large",
    description: "Scale up and track.",
    cta: "Buy Large",
    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "3000 products / month",
            featureDesc: null,
          },
          {
            featureName: "12 eCommerce channel",
            featureDesc: null,
          },

          {
            featureName: "1500 translations / month",
            featureDesc: null,
          },
          {
            featureName: "7 users",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "← PLUS",
        features: [
          {
            featureName: "Category Reports",
            featureDesc: null,
          },
          {
            featureName: "Review Responses",
            featureDesc: null,
          },
          {
            featureName: "Questions Analysis",
            featureDesc: null,
          },
          {
            featureName: "Questions Responses",
            featureDesc: null,
          },
        ],
      },
    ],
  },
  reviews_enterprise_display_only: {
    plan_id: "reviews_enterprise_display_only",
    name: "Enterprise",
    description: "Scale to more channels & sources.",
    cta: "Request Quote",
    durations: {
      month: {
        href: "/enterprise",
        price: "Custom",
      },
      year: {
        href: "/enterprise",
        price: "Custom",
      },
    },
    visible: true,

    features: [
      {
        heading: "INCLUDED",
        features: [
          {
            featureName: "Custom products / month",
            featureDesc: null,
          },
          {
            featureName: "30+ eCommerce channels",
            featureDesc: null,
          },
          {
            featureName: "100% translations",
            featureDesc: null,
          },
          {
            featureName: "Unlimited Users",
            featureDesc: null,
          },
        ],
      },
      {
        heading: "← PLUS",
        features: [
          {
            featureName: "Custom Channels",
            featureDesc: null,
          },
          {
            featureName: "Custom Models",
            featureDesc: null,
          },
          {
            featureName: "Instant Full Category Reports",
            featureDesc: null,
          },
          {
            featureName: "Dedicated Account Manager",
            featureDesc: null,
          },
        ],
      },
    ],
  },
}

export const triPlanComparison = [
  {
    optionHeader: "Usage",
    options: [
      {
        optionName: "Products per Month",
        optionDescription: "The number of unique products (sources) per month.",
        optionValues: {
          tri_reviews_small: "100",
          tri_reviews_medium: "1000",
          tri_reviews_large: "3000",
          reviews_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Number of Channels",
        optionDescription: "The number of eCommerce channels.",
        optionValues: {
          tri_reviews_small: "1",
          tri_reviews_medium: "7",
          tri_reviews_large: "12",
          reviews_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Channels",
        optionDescription: "The number of eCommerce channels.",
        optionValues: {
          tri_reviews_small: "Amazon.com",
          tri_reviews_medium: (
            <div>
              Amazon.com
              <br /> BestBuy.com
              <br /> Amazon.co.uk <br /> Amazon.ca <br /> Amazon.in <br />{" "}
              Flipkart.com <br />
              Costco.com
            </div>
          ),
          tri_reviews_large: (
            <div>
              Amazon.com
              <br /> BestBuy.com
              <br /> Amazon.co.uk <br /> Amazon.ca <br /> Amazon.in <br />{" "}
              Flipkart.com <br />
              Costco.com
              <br />
              Amazon.es <br /> Amazon.de <br /> Amazon.fr <br /> AppStore
              <br /> PlayStore
            </div>
          ),
          reviews_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Number of Automated Translations / Month",
        optionDescription:
          "The number of reviews or other items of feedback that's translated per month, from other languages to English.",
        optionValues: {
          tri_reviews_small: "300",
          tri_reviews_medium: "1000",
          tri_reviews_large: "1500",
          reviews_enterprise_display_only: "Custom",
        },
      },
      {
        optionName: "Number of Users",
        optionDescription: "The number of seats per plan.",
        optionValues: {
          tri_reviews_small: "1",
          tri_reviews_medium: "3",
          tri_reviews_large: "7",
          reviews_enterprise_display_only: "Unlimited",
        },
      },
    ],
  },
  {
    optionHeader: "Data",
    options: [
      {
        optionName: "Product Data (Basic)",
        optionDescription:
          "Data such as brand, ratings, reviews and its meta data are fetched.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Historical Data Fetch",
        optionDescription: "Historical reviews for products will be fetched.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Continuous Refresh",
        optionDescription:
          "Unlimited refreshes for tracked products for the month. ",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Product Data (Advanced)",
        optionDescription:
          "Data such as price and common product specifications are fetched, enabling segment based analysis at the category or brand level. ",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Language Processing",
    options: [
      {
        optionName: "Themes, Topics & Custom Tags",
        optionDescription:
          "Use custom built-in natural language processing, or configure your own tags using advanced keyword search. ",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Sentiment Classification",
        optionDescription:
          "See how users feel about your app, products & brand. Pinpoint problems and highlight wins so you can improve faster.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom ML Models & Ontologies",
        optionDescription:
          "Custom trained ML models for high accuracy in category context.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Analytics",
    options: [
      {
        optionName: "Reviews Analysis",
        optionDescription:
          "All review sentences will be classified into topics & sentiment.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Dynamic Reports & Dashboards",
        optionDescription:
          "Search, Sort & Filter by various meta data to generate different cuts of the reports in real time.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Comparison",
        optionDescription: "Compare stats, sentiment and review trends.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Question Analysis",
        optionDescription: "All questions classified into relevant topics.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Reports",
    options: [
      {
        optionName: "Product Reports",
        optionDescription:
          "Overview, Topics & Sentiment, SWOT, Search, Comparison, Product 360, Star Rating, Coverage.",
        optionValues: {
          tri_reviews_small: "yes",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Brand Reports",
        optionDescription:
          "Overview, Topics & Sentiment, SWOT, Search, Comparison, Product 360, Star Rating, Coverage.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Group Reports",
        optionDescription:
          "Overview, Topics & Sentiment, SWOT, Search, Comparison, Product 360, Star Rating, Coverage.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Category Reports",
        optionDescription:
          "Overview, Topics & Sentiment, SWOT, Search, Comparison, Product 360, Star Rating, Coverage.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Cross Category Reports",
        optionDescription:
          "Overview, Topics & Sentiment, SWOT, Search, Comparison, Product 360, Star Rating, Coverage.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Custom Reports & Dashboards",
        optionDescription:
          "Monitor all the KPIs you care about on a single page, and schedule each dashboard to be emailed automatically.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Alerts",
    options: [
      {
        optionName: "Email Reports  (Coming Soon!)",
        optionDescription:
          "Get useful summaries of data & insights sent via email daily, weekly or monthly.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "Coming Soon!",
          reviews_enterprise_display_only: "Coming Soon!",
        },
      },
      {
        optionName: "Alerts (Coming Soon!)",
        optionDescription:
          "Get instantly alerted when a new review is received, if the star rating drops, etc.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Respond",
    options: [
      {
        optionName: "Review Resposes",
        optionDescription: "Reply to reviews and questions from within the tool.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Response Integrations (Coming Soon!)",
        optionDescription: "Reply to reviews from Zendesk & Freshdesk",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "Coming Soon!",
        },
      },
    ],
  },
  {
    optionHeader: "Export",
    options: [
      {
        optionName: "Export to CSV & Excel",
        optionDescription: "Export data in CSV or XSLX format",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "API Access (Coming Soon!)",
        optionDescription: "Export data programmatically via a RESTful JSON API",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "Coming Soon!",
        },
      },
      {
        optionName: "Tableau Data Connector (Coming Soon!)",
        optionDescription: "Build your own reports inside Tableau",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "Coming Soon!",
        },
      },
    ],
  },
  {
    optionHeader: "Team Management",
    options: [
      {
        optionName: "Invite & Manage Team (Coming Soon!)",
        optionDescription: "Invite teammates and manage ACLs.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "yes",
          tri_reviews_large: "yes",
          reviews_enterprise_display_only: "yes",
        },
      },
    ],
  },
  {
    optionHeader: "Premium Support",
    options: [
      {
        optionName: "Account Manager",
        optionDescription:
          "Dedicated account manager to help with support & customizations.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Procurement Support",
        optionDescription:
          "Procurement documentation eg. vendor or security onboarding questionnaires",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Invoice Billing",
        optionDescription: "Pay via Invoice rather than Credit Card",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "yes",
        },
      },
      {
        optionName: "Single Sign-On (SSO) (Coming Soon!)",
        optionDescription:
          "Integrate Currents with your SAML 2.0 compatible SSO provider.",
        optionValues: {
          tri_reviews_small: "no",
          tri_reviews_medium: "no",
          tri_reviews_large: "no",
          reviews_enterprise_display_only: "Coming Soon!",
        },
      },
    ],
  },
]
