import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import Image from "./image"
import Link from "next/link"

Logo.propTypes = {
  isDarkMode: PropTypes.bool, // default (light)
  logoObj: PropTypes.object,
  color: PropTypes.string, // white, black (default), blue
  version: PropTypes.string, // icon, full (default)
  url: PropTypes.string,
  className: PropTypes.string,
}

export default function Logo(props) {
  const [logo, setLogo] = useState()

  useEffect(() => {
    setLogo(getLogo(props.color, props.isDarkMode))
  }, [props.color, props.isDarkMode])

  const link = props.url

  if (!logo) return null

  return (
    <div
      className={
        "flex items-center flex-shrink-0 " + (props.className ? props.className : "")
      }
    >
      <div className="h-full w-auto">
        {link ? (
          <Link href={link} legacyBehavior>
            <a>{renderLogo()}</a>
          </Link>
        ) : (
          renderLogo()
        )}
      </div>
    </div>
  )

  function renderLogo() {
    return (
      <Image
        className="h-full w-full object-contain"
        src={`/logo/${logo}`}
        alt="logo"
      />
    )
  }

  function getLogo(color, isDarkMode) {
    if (!props.logoObj) return

    let col = color ? color : isDarkMode ? "white" : "black"
    let logo = props.logoObj[col]
    if (props.version == "icon") logo = props.logoObj[`icon-${col}`]

    return logo
  }
}
