import { isObjectsEqual } from "./util"

export const ErrorCodes = Object.freeze({
  MULTI: "Please correct the errors in the form.",
  GENERAL: "Something went wrong. Please try again.",
  GENERAL_ACKNOWLEDGED:
    "Something went wrong. We've made a note of this and will be fixing this asap!",
  EMPTY: "Please complete all required fields.",
  EMPTY_LOGIN: "Please enter both email and password.",
  INCORRECT_EMAIL_PWD: "Please enter correct email id and password.",
  DUPLICATE_ACCOUNT: "An account already exists with this email.",
  "type_error.integer": "One of the fields expects a numeric value.",
})

/*
export function isFormCompleted(form) {
  let temp = form
  let isFormComplete = true
  for (let key in temp) {
    let details = temp[key]
    if ("errorMessage" in details && details["errorMessage"] != undefined && details["errorMessage"].length > 0)
      isFormComplete = false
  }
  return isFormComplete
}
*/

/**
 *
 * @param {object} form -> FormConfigs with updated form states.
 * @returns {object} -> Form values
 */
export function getDetailsFromForm(form) {
  let details = {}
  getDetailFromConfig(form)
  function getDetailFromConfig(form, parentKey, parentValue) {
    for (const key in form) {
      if (form[key].field != "info") {
        const isChild = parentKey != null
        const shouldAddField =
          !isChild || (form[key].rules && form[key].rules.value === parentValue)
        if (form[key].children) {
          const { value } = form[key]
          if (shouldAddField) details = { ...details, [key]: value }
          getDetailFromConfig(form[key].children, key, value)
        } else if (Array.isArray(form[key])) {
          const group = form[key].map((config) => {
            let detail = {}
            for (const key in config) {
              detail = { ...detail, [key]: { value: config[key].value } }
            }
            return detail
          })
          details = { ...details, [key]: group }
        } else {
          if (shouldAddField) details = { ...details, [key]: form[key].value }
        }
      }
    }
  }
  return details
}

export function isFormCompleted(form, formConfig, enforceEdit) {
  let isFormCompleted = true

  for (const key in form) {
    checkFormValue(form[key])
  }

  function checkFormValue(details, parentFieldValue) {
    if (Array.isArray(details)) {
      for (const config of details) {
        for (const key in config) {
          checkFormValue(config[key])
        }
      }
    } else {
      const {
        field,
        value,
        required,
        max,
        min,
        maxLength,
        minLength,
        type,
        rules,
        children,
      } = details

      if (required) {
        if (parentFieldValue && rules && parentFieldValue != rules["value"]) {
          return
        }
        if (field === "multi_input" || field === "combobox") {
          if (value.length === 0) {
            isFormCompleted = false
            return
          }
        } else if (value == null || value === "") {
          isFormCompleted = false
          return
        }

        if (type === "email" && !value.includes("@")) {
          isFormCompleted = false
          return
        }

        if (max && Number(value) > max) {
          isFormCompleted = false
          return
        }

        if (min && Number(value) < min) {
          isFormCompleted = false
          return
        }

        if (maxLength && value.length > maxLength) {
          isFormCompleted = false
          return
        }

        if (minLength && value.length < minLength) {
          isFormCompleted = false
          return
        }
      }
      if (children) {
        for (const key in children) {
          checkFormValue(children[key], value)
        }
      }
    }
  }

  if (enforceEdit) {
    //⚠️ COMPARES THE EXISTING CONFIG VALUES WITH THE LATEST ONE TO DETECT "EDIT"
    const originalForm = getDetailsFromForm(formConfig)
    const updatedForm = getDetailsFromForm(form)
    isFormCompleted = !isObjectsEqual(originalForm, updatedForm) && isFormCompleted
  }
  return isFormCompleted
}

export function resetForm(form) {
  const temp = {}
  for (const key in form) {
    const details = form[key]
    details.value = ""
    temp[key] = details
  }
  return temp
}
