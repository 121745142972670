import { joinClassNames } from "components/common/lib/util"

export default function Container({ children, center = true }) {
  return (
    <div
      className={joinClassNames(
        "max-w-8xl px-4 sm:px-6 md:px-16 bg-background",
        center ? " mx-auto" : ""
      )}
    >
      {children}
    </div>
  )
}

export function ContainerFull({ children }) {
  return (
    <div className="max-w-full max-h-full px-4 sm:px-6 md:px-12  bg-background text-foreground ">
      {children}
    </div>
  )
}

export function Container4xl({ children }) {
  //🚦Faqs are too stretched under normal containers, use this to condense it.
  return (
    <div className="h-full flex space-y-12 flex-col mx-auto max-w-4xl px-5 sm:px-10 md:px-20 lg:px-0 max-h-full bg-background text-foreground">
      {children}
    </div>
  )
}

export function ContainerLanding({ children }) {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 bg-background ">
      {children}
    </div>
  )
}

export function Container8xl({ children }) {
  return <div className="max-w-8xl mx-auto px-4 sm:px-6">{children}</div>
}

export function WriterContainer({ children }) {
  return <div className=" w-[95vw] md:w-[80vw] mx-auto h-full">{children}</div>
}

export function VerticalCenterContainer({ children, minusValue = 0 }) {
  return (
    <div
      className="flex flex-col justify-center w-full "
      style={{ minHeight: `calc(100vh - ${minusValue}px)` }}
    >
      {children}
    </div>
  )
}
