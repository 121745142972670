import { useState } from "react"
import { DateRangePicker as DateRangePickerExternal } from "react-date-range"
import {
  getDateDigitsFromDateObj,
  getDateObjFromDateDigits,
} from "@common/datePickers/utils"

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
  startOfQuarter,
  endOfQuarter,
} from "date-fns"

const defineds = {
  startOfYear: startOfYear(new Date()),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),

  startOfLast7Days: startOfDay(addDays(new Date(), -1)),
  endOfLast7Days: endOfDay(addDays(new Date(), -7)),

  startOfLast30Days: startOfDay(addDays(new Date(), -1)),
  endOfLast30Days: endOfDay(addDays(new Date(), -30)),

  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastSixMonth: startOfDay(addDays(new Date(), -181)),
  endOfLastSixMonth: endOfDay(addDays(new Date(), -1)),
  startOfLastYear: startOfDay(addDays(new Date(), -366)),
  endOfLastYear: endOfDay(addDays(new Date(), -1)),
  startOfAllTime: startOfDay(addDays(new Date(), -3651)),
  endOfAllTime: endOfDay(addDays(new Date(), -1)),
  startOfLastQuarter: startOfQuarter(addDays(startOfQuarter(new Date()), -1)),
  endOfLastQuarter: endOfQuarter(addDays(startOfQuarter(new Date()), -1)),
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  },
}

export const ranges = {
  /*
  'today': {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
      value: 'today',
    }),
  },
  'yesterday': {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
      value: 'yesterday',
    }),
  },
  'this_week': {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
      value: 'this_week',
    }),
  },
  'last_week': {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      value: 'last_week',
    }),
  },
  'last_7_days': {
    label: 'Last 7 days',
    range: () => ({
      startDate: defineds.startOfLast7Days,
      endDate: defineds.endOfLast7Days,
    }),
  },
  'this_month': {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
      value: 'this_month',
    }),
  },*/

  last_week: {
    label: "Last Week",
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      value: "last_week",
    }),
  },
  last_month: {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      value: "last_month",
    }),
  },
  "1mon": {
    label: "Last 30 Days",
    range: () => ({
      startDate: defineds.startOfLast30Days,
      endDate: defineds.endOfLast30Days,
      value: "1mon",
    }),
  },
  "6mon": {
    label: "Last 6 Months",
    range: () => ({
      startDate: defineds.startOfLastSixMonth,
      endDate: defineds.endOfLastSixMonth,
      value: "6mon",
    }),
  },
  last_quarter: {
    label: "Last Quarter",
    range: () => ({
      startDate: defineds.startOfLastQuarter,
      endDate: defineds.endOfLastQuarter,
      value: "last_quarter",
    }),
  },
  ytd: {
    //🚦 FROM 1ST OF CURRENT YEARS JAN TO TODAY
    label: "Year To Date",
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfToday,
      value: "ytd",
    }),
  },
  "1yr": {
    label: "Last 1 Year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
      value: "1yr",
    }),
  },
  all: {
    label: "All Time",
    range: () => ({
      startDate: defineds.startOfAllTime,
      endDate: defineds.endOfAllTime,
      value: "all",
    }),
  },
}

export function createStaticRanges(ranges) {
  return Object.values(ranges).map((range) => ({
    ...staticRangeHandler,
    ...range,
  }))
}

export const staticRanges = createStaticRanges(ranges)

export default function DateRangePicker({
  initialState,
  initialPresetKey,
  onSelection,
}) {
  const [selectionRange, setSelectionRange] = useState(getInitialState())
  return (
    <div className="p-2">
      <DateRangePickerExternal
        ranges={[selectionRange]}
        onChange={handleSelect}
        maxDate={new Date()}
        staticRanges={createStaticRanges(staticRanges)}
      />
    </div>
  )

  function handleSelect(ranges) {
    if (onSelection)
      if (
        "value" in ranges.selection &&
        typeof ranges.selection["value"] != "undefined"
      ) {
        onSelection([ranges.selection.value])
      } else {
        onSelection([
          getDateDigitsFromDateObj(ranges.selection.startDate),
          getDateDigitsFromDateObj(ranges.selection.endDate),
        ])
      }
    ranges.selection.value = undefined // need to reset it as the value of 'value' is preserved from previous selection.
    setSelectionRange(ranges.selection)
  }

  function getInitialState() {
    if (initialState && initialState.length == 1 && initialState[0] in ranges) {
      let selection = ranges[initialState[0]].range()
      selection["key"] = "selection"
      selection["value"] = undefined
      return selection
    } else if (initialState && initialState.length == 2)
      return {
        startDate: getDateObjFromDateDigits(initialState[0]),
        endDate: getDateObjFromDateDigits(initialState[1]),
        key: "selection",
      }
    else
      return {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      }
  }
}
