import { useRouter } from "next/router"
import {
  addFilter,
  getFilterValue,
  replaceFilter,
  addFilterIfNotPresent,
  getFiltersStateFromUrl,
} from "./filters"
import { THEME } from "configs/theme"
import {
  getDecodedURIComponent,
  getObjectFromJSONString,
  getReportIdFromEntityObj,
} from "./util"
import {
  isObjectsEqual,
  filterObjects,
  getQueryParamsFromUrl,
  isNullOrEmpty,
  filterUndefinedFromObject,
} from "components/common/lib/util"
import { removeQueryParams } from "components/common/lib/navigation"
import { getFiltersStateFromOnSelect } from "lib/filtersNew"

// TODO - Cleanup.
// Only functions that deal with the url or manipulate the url should be here.
// Layer #0 - Functions to replace individual parts of url e.g replaceFilterValue, replaceFirstFilterValue
// Layer #1 - Specific Utility Functions e.g isDashboard, , etc
// Layer #2 - Functions to redirect to individual pages given current page context e.g getManagePlansUrl or getDashboardLinkFromParts.

// provide the newFullPageKey or (newPageKey & newSubPageKey). If not provided, the current fullPageKey is kept as is.
export function getPathForNewEntity(
  path,
  query,
  entityId,
  categoryId,
  reportId,
  overridePageKey,
  time
) {
  const _time = query.time || time
  path = overridePageKey ? replacePageKey(path, overridePageKey) : path

  /* this creates a minified url for new entity by discarding any previously selected filters */
  /*
  path = addFilter(path.split("?")[0], "ids", entityId)
  path = addFilter(path, "report_id", reportId)
  path = addFilter(path, "cat_id", categoryId)
  path = addFilter(path, "time", _time)
  path =
    pageKey?.toLowerCase() == "search" && query?.text
      ? addFilter(path, "text", query.text)
      : path
  */

  /* below code just replaces the new filters. doesn't remove all others */
  path = replaceFilter(path, "ids", entityId)
  path = replaceFilter(path, "report_id", reportId)
  path = replaceFilter(path, "cat_id", categoryId)
  path = replaceFilter(path, "time", _time)
  path = getPathWithoutLocalQueryParams(path)
  return path
}

export function replaceCategoryId(path, newCategoryId) {
  return addFilter(path, "cat_id", newCategoryId)
}

export function getRedirectPathForNewCategory(
  path,
  query,
  entityObj,
  newCategoryId,
  time,
  existingReportId = ""
) {
  const _time = query.time || time
  const reportId = getReportIdFromEntityObj(entityObj, existingReportId)
  let newPath = replaceCategoryId(path, newCategoryId)
  newPath = replaceReportId(newPath, reportId, query)
  newPath = removeQueryParams(newPath, "label_sub_cat")
  newPath = getPathWithoutLocalQueryParams(newPath)
  newPath = addFilter(newPath, "time", _time)
  return newPath
}
// given new page key (groups, explore, etc), constructs the link
// entityObj only required to find reportId for stats redirect
export function getRedirectPath(newPageKey) {
  const router = useRouter()
  if (newPageKey == "help") return THEME["helpLink"]
  let path = router.asPath
  path = replacePageKey(path, newPageKey)
  path = getPathWithoutLocalQueryParams(path)
  path = removeQueryParams(path, "compare")

  /*
   * removed so that current filter context can be retained when switching pages and reports
  path = path.split("?")[0]
  path = addFilter(path, "cat_id", catId)
  path = addFilter(path, "ids", eids?.join(","))
  path = addFilter(path, "report_id", reportId)
  path = addFilter(path, "time", time)
  */

  return path
}

export function getRedirectPathForReport(oldPath, reportId) {
  return addFilter(oldPath, "report_id", reportId)
}

// returns page context e.g explore, 'manage', 'manage/settings' 'manage/topics/create' etc
export function getPageKey(path) {
  if (path) {
    const [pathWithoutQuerystring] = path.split("?")
    const parts = pathWithoutQuerystring.split("/")
    parts.splice(0, 3)
    const key = parts.join("/")
    return key
  }
}

export function replacePageKey(path, newPageKey) {
  if (path) {
    const pageKey = getPageKey(path)
    const parts = path.split("/")
    const basePath = parts.slice(0, 3).join("/")
    const pagePath = parts.slice(3, parts.length).join("/")
    const newPagePath = pagePath.replace(
      new RegExp("\\b" + pageKey + "\\b"),
      newPageKey
    )
    return basePath.concat("/", newPagePath)
  }
}

// // returns current page key e.g stats, explore
// export function getPageKey(path, query) {
//   return getPageKey(path, query)?.split("/")[0]
// }

export function getHomePathForNextStep(path, nextStep) {
  return addFilter(path, "step", nextStep)
}

/**
 *
 * @returns {string} reportId
 */
export function getReportId() {
  const router = useRouter()
  const { report_id } = router.query
  return report_id instanceof Array ? report_id.at(-1) : report_id
}

export function replaceReportId(path, newReportId) {
  const newPath = addFilter(path, "report_id", newReportId)
  return newPath
}

export function getStatsPageUrl(path, query) {
  const newPath = replacePageKey(path, "stats", query)
  return newPath
}

export function getManagePlansUrl(path, query) {
  const newPath = replacePageKey(path, "settings/plans", query)
  return newPath
}

export function getHomePageUrl(customerId) {
  return `/d/${customerId}/home`
}

export function getMeetPageUrl(path) {
  return replacePageKey(path, "meet")
}

export function getChannelsPageUrl(path) {
  return replacePageKey(path, "channels")
}

export function getManageChannelsPageUrl(path) {
  return replacePageKey(path, "manage/channels")
}

export function getSourcesPageUrl(path) {
  return replacePageKey(path, "manage/tasks")
}
export function getCreateSourcesPageUrl(path) {
  return replacePageKey(path, "manage/tasks/create")
}

export function getComparePageUrl(path, ids) {
  const oldPath = path
  if (!path || !Array.isArray(ids)) return path
  const compare = ids.map((eid) => {
    const path = replaceFilter(oldPath, "ids", eid)
    const query = path.split("?")[1]
    return {
      query,
    }
  })

  return addFilter(oldPath, "compare", encodeURIComponent(JSON.stringify(compare)))
}

export function getDashboardLinkFromParts(
  custId,
  pageKey,
  querystring,
  cat_id,
  report_id
) {
  if (!custId || !pageKey) return null
  let path = `/d/${custId}/${pageKey}`
  path += querystring ? `?${querystring}` : ""

  /**
   * We added these checks to ensure that old bookmarks works
   * TODO: can remove  when all bookmarks are migrated
   */
  if (!path.includes("cat_id=")) path = addFilter(path, "cat_id", cat_id)
  if (!path.includes("report_id=")) path = addFilter(path, "report_id", report_id)
  return path
}

/* LAYER #1 */

export function isDashboard(path) {
  return path.indexOf("/d/") != -1
}

export function isUrlsEqual(url1, url2) {
  if (!url1 || !url2) return false
  if (url1.substring(0, url1.indexOf("?")) !== url2.substring(0, url2.indexOf("?")))
    return false

  const obj1 = getQueryParamsFromUrl(url1)
  const obj2 = getQueryParamsFromUrl(url2)
  if (!isObjectsEqual(obj1, obj2)) return false
  return true
}

/* LAYER #1 */

// returns the entityKey which is categoryId/entityId
export function getEntityKey(entityId = "", categoryId) {
  if (entityId.indexOf("/") == -1) return `${categoryId}/${entityId}`
  else {
    const entity = entityId.split("/")[1]
    return `${categoryId}/${entity}`
  }
}

export function appendEntityId(path, existingIds, newId) {
  return replaceFilter(path, "ids", `${existingIds.join(",")},${newId}`)
}

export function replaceEntityId(path, existingIds, idToReplace, replacementId) {
  existingIds = existingIds.map(function (item) {
    return item == idToReplace ? replacementId : item
  })
  return replaceFilter(path, "ids", `${existingIds.join(",")}`)
}

export function resetEntityId(path, replacementId) {
  return replaceFilter(path, "ids", `${replacementId}`)
}

export function removeEntityId(path, existingIds, idToRemove) {
  const updatedIds = existingIds.filter((id) => id !== idToRemove)
  return replaceFilter(path, "ids", `${updatedIds.join(",")}`)
}

export function removeEntityIdCatId(path) {
  return removeQueryParams(path, ["ids", "cat_id"])
}

export function getPathWithSentiment(newPath, newSenti) {
  return newSenti
    ? addFilterIfNotPresent(newPath, "senti", newSenti)
    : removeQueryParams(newPath, "senti")
}

// <---- FOR TRI ---->
export function getCountry(router) {
  const { country } = router.query
  return country || "us"
}
/* LAYER #0 */

export function removeFilterValue(path, query, filter, tag) {
  if (!path || !tag || Object.keys(query).length == 0) return path

  const text = getFilterValue(query, filter)
  // This would encoded, so before comparing we need to decode it
  if (getDecodedURIComponent(text).indexOf(tag) == -1) return path

  const textArr = text.split(",")
  const tagPosition = textArr.indexOf(tag)
  textArr.splice(tagPosition, 1)

  let newPath = path
  if (textArr.length > 0) newPath = replaceFilter(path, filter, textArr.join(","))
  else newPath = removeQueryParams(path, filter)
  return newPath
}

/**
 *
 * @param {NextRouter} router -> router object returned from `useRouter()` hooks
 * @param {String} newPageKey -> url params
 * @param {Number} direction default = 1
 * @param {Object} newQuery -> url query params
 *
 *    `0` ->update the current pageKey with new one
 *
 *    `1` -> Go forward , this will append the new pageKey key with current path
 *
 *    `-1` ->Go back , this will remove the `string` after new page key
 * @param {Array} removedQueryKeys -> list of query keys which should be removed from url
 */
export const navigate = ({
  router, // the next.js router object
  newPageKey, // the new page key to navigate to
  direction = 1, // the direction of navigation: 1 for forward 0 for update current pageKey and -1 for backward
  newQuery = {}, // the new query parameters to add to the URL
  removedQueryKeys = [], // the keys of query parameters to remove from the URL
  replace = false,
  tab, // if tab="new", this will open an new tab
}) => {
  if (!router) return //throw Error("Router object should not be null")
  if (newPageKey === "/") router.push("/")

  const [pathname] = router.asPath.split("?")
  const currentPageKey = pathname.split("/")?.at(-1)
  let newPath
  if (newPageKey) {
    if (direction === 1) newPath = `${pathname}/${newPageKey}`
    if (direction === 0) {
      newPath = pathname.replace(currentPageKey, newPageKey)
    }
    if (direction === -1 && pathname.includes(newPageKey)) {
      newPath = pathname.substring(
        0,
        pathname.indexOf(newPageKey) + newPageKey.length
      )
    }
  }

  const query = { ...router.query, ...newQuery } // add new query to existing one

  // remove all the queries passed to `@removedQueryKeys`
  const filteredQuery = filterObjects(
    query,
    (_, key) =>
      !removedQueryKeys.includes(key) && !["customerId", "docId"].includes(key)
  )

  const path = newPath || pathname
  if (tab == "new") {
    const queryString = new URLSearchParams(filteredQuery).toString()
    if (window) {
      if (queryString) window.open(`${path}?${queryString}`, "_blank")
      else window.open(path, "_blank")
    }
    return
  }

  if (replace) {
    router.replace({
      pathname: path,
      query: filteredQuery,
    })
  } else {
    router.push({
      pathname: path,
      query: filteredQuery,
    })
  }
}

export const navigateToDocs = (router, customerId) => {
  router.push(`/d/${customerId}/c/docs`)
}

/*------------------------------------LOCAL QUERY HANDLER FUNCTIONS ---------------------- */

// Serialize query string
export const serializeObjectToQueryString = (queryObject) => {
  if (isNullOrEmpty(queryObject)) return ""
  const jsonString = JSON.stringify(queryObject)
  return encodeURIComponent(jsonString)
}

// Deserialize object
export const deserializeQueryStringToObject = (encodedQuery) => {
  if (encodedQuery) {
    const jsonString = getDecodedURIComponent(encodedQuery)
    return getObjectFromJSONString(jsonString)
  }
}
export const getPathWithoutLocalQueryParams = (path) => {
  return removeQueryParams(path, ["comment", "input_state"])
}

export function getQueryObject(router, queryKey) {
  if (!router || !queryKey) return {}
  const queryStringValue = getFilterValue(router.query, queryKey)
  return deserializeQueryStringToObject(queryStringValue) || {}
}

export function getPathWithQueryObject(router, state, queryKey) {
  const existingQueryObject = getQueryObject(router, queryKey)
  return addFilter(
    router.asPath,
    queryKey,
    serializeObjectToQueryString({ ...existingQueryObject, ...state })
  )
}

export function getCommentQueryObject(router) {
  const commentQueryString = getFilterValue(router.query, "comment")
  const commentQueryObject = deserializeQueryStringToObject(commentQueryString)
  return commentQueryObject || {}
}

export function getPathWithCommentQueryObject(
  router,
  queryObject = {},
  keepExisting = true
) {
  const commentQueryObject = getCommentQueryObject(router)
  const newCommentQueryObject = filterUndefinedFromObject({
    ...(keepExisting ? commentQueryObject : {}),
    ...queryObject,
  })
  return replaceFilter(
    router.asPath,
    "comment",
    serializeObjectToQueryString(newCommentQueryObject)
  )
}
/**
 *This is used get get filters state from flattened on_select object
 * @param {Router} router
 * @param { any} queryObject
 * @param {any} filters -> not require to pass this.
 */
export function getFiltersStateFromQueryObject(
  router,
  queryObject = {},
  filters = {}
) {
  const path = getPathWithoutLocalQueryParams(router.asPath)
  const filtersState = getFiltersStateFromUrl(path, filters, false, true)
  const newFilterState = getFiltersStateFromOnSelect(
    path,
    { filters: { ...queryObject } },
    filters
  )
  return { ...filtersState, ...newFilterState }
}
