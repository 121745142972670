/* eslint-disable @typescript-eslint/no-empty-function */
import { useTheme } from "next-themes"
import React, { ReactNode, createContext, useContext, useEffect } from "react"

import {
  showToastAdapter,
  ToastContainerWrapper,
  StatusOptions,
  showPromiseToastAdapter,
  updateToastAdapter,
  clearToastAdapter,
  ToastOptionsType,
} from "./toastAdapter"

export type ShowPromiseToast = <T>(
  promise: Promise<T>,
  statusOptions: StatusOptions,
  options?: ToastOptionsType
) => Promise<T>
interface ToastContextType {
  showToast: (message: string, options?: ToastOptionsType) => number | string
  updateToast: (toastId: number | string, options?: ToastOptionsType) => void
  clearToast: (toastId: number | string) => void
  showPromiseToast: ShowPromiseToast
}

const ToastContext = createContext<ToastContextType>({
  showToast: () => 0,
  updateToast: () => {},
  clearToast: () => {},
  showPromiseToast: (() => Promise.resolve()) as <T = void>(
    promise: Promise<T>,
    options?: ToastOptionsType
  ) => Promise<T>,
})

export function ToastProvider({ children }: { children: ReactNode }) {
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"

  //simple toast
  const showToast = (
    content: ReactNode,
    options: ToastOptionsType = {}
  ): number | string => {
    return showToastAdapter(content, options)
  }

  //makes changes to the currently displayed toast
  const updateToast = (toastId: number | string, options: ToastOptionsType = {}) => {
    options.icon = options.icon ?? null
    options.autoClose = options.autoClose ?? 1000
    options.render =
      options.type === "success"
        ? options.render ?? "Updated Successfully."
        : options.type === "error"
          ? options.render ?? "Update Failed."
          : options.render
    updateToastAdapter(toastId, options)
  }

  //clears specific toast
  const clearToast = (toastId: number | string) => {
    clearToastAdapter(toastId)
  }

  //toast-handles loading - success - failure for promises
  const showPromiseToast = async (
    promise: Promise<any>,
    statusOptions: StatusOptions,
    options: ToastOptionsType = {}
  ) => {
    const defaultStatusOptions: StatusOptions = {
      pending: "Loading...",
      success: "Success!",
      error: "Error!",
    }
    return showPromiseToastAdapter(
      promise,
      { ...defaultStatusOptions, ...statusOptions },
      options
    )
  }

  useEffect(() => {
    const showToastListener = (e: CustomEvent) => {
      if (e.detail?.message && e.detail?.options) {
        showToast(e.detail.message, e.detail.options)
      }
    }

    window.addEventListener("show-toast", showToastListener as EventListener)
    return () =>
      window.removeEventListener("show-toast", showToastListener as EventListener)
  }, [showToast])

  return (
    <ToastContext.Provider
      value={{ showToast, showPromiseToast, updateToast, clearToast }}
    >
      {children}
      <ToastContainerWrapper
        theme={isDarkMode ? "dark" : "light"}
        toastClassName={`border`}
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
      />
    </ToastContext.Provider>
  )
}

export function useToast() {
  const { showToast, showPromiseToast, updateToast, clearToast } =
    useContext(ToastContext)
  return {
    showToast,
    showPromiseToast,
    updateToast,
    clearToast,
  }
}
