import React from "react"
import PropTypes from "prop-types"
import styles from "./loader.module.css"
import Svg from "./svg"

Loader.propTypes = {
  type: PropTypes.string, // default: 'three-dot'. Spin
}

export default function Loader(props) {
  if (props.type == "spin")
    return (
      <Svg
        name="spin-loader"
        classes="animate-spin h-5 w-5 mr-3 text-blue-500"
      />
    )

  return (
    <div className="h-full flex justify-center items-center">
      <div className={styles.spinner}>
        <div className={"bg-blue-300 opacity-50 " + styles.bounce1} />
        <div className={"bg-blue-300 opacity-50 " + styles.bounce2} />
        <div className={"bg-blue-300 opacity-50 " + styles.bounce3} />
      </div>
    </div>
  )
}
