import { useRouter } from "next/router"
import React from "react"

interface ImageProps {
  src: string;
}

export default function Image({ src, ...rest }: ImageProps) {
  const router = useRouter()
  const { basePath } = router
  const finalSrc = basePath.startsWith("/") ? `${basePath}${src}` : src
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...rest} src={finalSrc} />
}
