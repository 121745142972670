// External package imports
import React from "react"
import SourceMap from "source-map"

// Components imports
import LandingSimpleLayout from "components/layouts/landingSimpleLayout"
import { VerticalCenterContainer } from "@common/container"
import Button from "@common/buttons/button"

// Utility imports
import { recordErrorInForm } from "@common/lib/util"
import Router from "next/router"
import { replacePageKey } from "lib/navigation"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(_error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    if (process.env.NODE_ENV === "production")
      this.mapStackTrace(errorInfo?.componentStack)
        .then((errorString) => {
          recordErrorInForm(
            error.message,
            `${errorString}, originaltrace: ${errorInfo?.componentStack}`
          )
        })
        .catch((error) => {
          console.log(error)
        })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LandingSimpleLayout>
          <VerticalCenterContainer minusValue={168}>
            <div className="text-center space-y-4">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-5xl">
                Oops, there is an error!
              </h1>
              <div className="space-x-4">
                <Button
                  variant="minimal"
                  onClick={() => this.setState({ hasError: false })}
                >
                  Try again
                </Button>

                <Button
                  onClick={() => {
                    this.setState({ hasError: false })
                    Router.push(
                      replacePageKey(Router.asPath?.split("?")[0], "/home")
                    ).then(() => Router.reload())
                  }}
                  variant="primary"
                >
                  Go To Dashboard Home
                </Button>
              </div>
            </div>
          </VerticalCenterContainer>
        </LandingSimpleLayout>
      )
    }

    return this.props.children
  }

  async getSourceMapForUrl(url) {
    // Fetch the source map file for the given URL.
    const mapUrl = `${url}.map`
    const response = await fetch(mapUrl)
    const text = await response.text()
    return new SourceMap.SourceMapConsumer(text)
  }

  async mapStackTrace(stackTrace) {
    // Split the stack trace into individual lines.
    const lines = stackTrace.trim().split("\n")

    // Map each line of the stack trace to the original source location.
    const mappedLines = await Promise.all(
      lines.map(async (line) => {
        // Parse the stack trace line to get the URL and line/column numbers.
        const match = /at (.+?) \((.+?):(\d+):(\d+)\)/.exec(line)
        if (!match) {
          return line
        }

        const url = match[2]
        const lineNo = parseInt(match[3], 10)
        const colNo = parseInt(match[4], 10)

        // Load the source map for the URL and use it to map the location.
        const consumer = await this.getSourceMapForUrl(url)
        const mapped = consumer.originalPositionFor({ line: lineNo, column: colNo })

        // Return the mapped stack trace line.
        return `at ${match[1]} (${mapped.source}:${mapped.line}:${mapped.column})`
      })
    )
    return mappedLines.join("\n")
  }
}
