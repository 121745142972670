export const currentsFooterLinks = {
  simple: {
    links: [
      { name: "About", link: "https://currents.ai/about" },
      { name: "Contact", link: "https://currents.ai/contact" },
      { name: "Blog", link: "https://currents.ai/blog" },
      { name: "FAQs", link: "https://currents.ai/faqs" },
      { name: "Privacy", link: "https://currents.ai/privacy" },
      { name: "Terms", link: "https://currents.ai/terms" },
    ],
  },
  primary: {
    1: {
      name: "Company",
      links: [
        { name: "About", link: "https://currents.ai/about" },
        { name: "Contact", link: "https://currents.ai/contact" },
        { name: "Blog", link: "https://currents.ai/blog" },
        { name: "FAQs", link: "https://currents.ai/faqs" },
        { name: "Privacy", link: "https://currents.ai/privacy" },
        { name: "Terms", link: "https://currents.ai/terms" },
      ],
    },
    2: {
      name: "Use Cases",
      links: [
        {
          name: "Amazon Review Analysis",
          link: "",
        },
        { name: "App Store", link: "" },
        { name: "Chat Transcripts", link: "" },
        { name: "CSAT / NPS Survey", link: "" },
        { name: "Support Tickets", link: "" },
      ],
    },
    3: {
      name: "Verticals",
      links: [
        { name: "Electronics", link: "" },
        { name: "Hospitality", link: "" },
        { name: "Lifestyle", link: "" },
        { name: "Gaming", link: "" },
        { name: "FMCG", link: "" },
        { name: "SaaS", link: "" },
      ],
    },
    4: {
      name: "Capabilities",
      links: [
        { name: "Data Aggregation", link: "" },
        { name: "Feedback Analytics", link: "" },
        { name: "Topic Classification", link: "" },
        { name: "Sentiment Analysis", link: "" },
      ],
    },
  },
}
