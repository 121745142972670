import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../configs/devConfigs/tailwind.config.cjs"
import Showdown from "showdown"
import {
  compareItems,
  rankItem,
  rankings,
  Ranking,
} from "@tanstack/match-sorter-utils"
const converter = new Showdown.Converter()

const fullConfig = resolveConfig(tailwindConfig)

// TODO - make sections -> ListUtil, ObjectUtil

export function round(number, decimal = 1) {
  if (isNaN(number) == true) return number

  if (decimal == 0) return Math.round(number)

  return Math.round(number * 10) / 10
}

export function truncateToDecimal(number, decimal = 1) {
  if (typeof number !== "number") return
  return parseFloat(number.toFixed(decimal))
}

export const getHashFromString = (str) => {
  if (!str) return
  const arr = str.split("")
  return arr.reduce(
    (hashCode, currentVal) =>
      (hashCode =
        currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
    0
  )
}

// replaces all occurances of oldStr with newStr
export function replaceAll(str, oldStr, newStr) {
  if (str == undefined || oldStr == undefined || newStr == undefined) return str

  return str.split(oldStr).join(newStr)
}

// insert into array if element not already present
export function addToArray(array, element) {
  const index = array.indexOf(element)
  if (index == -1) array.push(element)
  return array
}

// remove from array
export function removeFromArray(array, element) {
  const index = array.indexOf(element)
  if (index > -1) array.splice(index, 1)
  return array
}

export function removeArrayFromArray(array, subArray) {
  let newArray = array
  for (const idx in subArray) {
    const index = array.indexOf(subArray[idx])
    if (index > -1) {
      newArray = removeFromArray(array, subArray[idx])
    }
  }
  return newArray
}

export function removeFromArrayAtPosition(array, position) {
  if (position > -1)
    //console.log("boo", array.splice(position, 1))
    return array
}

// Screen Breakpoints
export function isSmall() {
  return screen.width < fullConfig.theme.screens.md.split("px")[0]
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getCommaSeparatedStrFromArray(arr) {
  if (!arr || arr.length == 0) return
  return arr.join(",")
}

/**
 *
 * @param {string} input
 * @param {Record<string,string>} dataToBeSearched
 * @param {Ranking} ranking
 * @returns
 */
export function localSearch(
  input,
  dataToBeSearched = {},
  ranking = rankings.CONTAINS
) {
  //            input  --->  string expected
  // dataToBeSearched  --->  array of objects expected

  let results = []
  if (!input) return results

  const threshold = ranking
  const rankedItems = []

  for (const [key, value] of Object.entries(dataToBeSearched)) {
    const rankingInfo = rankItem(value, input, {
      accessors: [{ accessor: (item) => item }],
      threshold,
    })
    if (rankingInfo.passed) {
      rankedItems.push({ key, rankingInfo })
    }
  }
  results = rankedItems
    .sort((a, b) => compareItems(a.rankingInfo, b.rankingInfo))
    .map((item) => item.key)

  return results
}

export function joinClassNames(...classes) {
  const classList = classes.map((className) => (className ? className.trim() : ""))
  return classList.join(" ")
}

/**
 *
 * @param {int} duration -> duration in seconds
 * @returns {Promise}
 */

export async function delayInSeconds(duration = 0) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), duration * 1000)
  })
}

export function isObjectsEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true
  } else if (
    typeof obj1 == "object" &&
    obj1 != null &&
    typeof obj2 == "object" &&
    obj2 != null
  ) {
    if (Object.keys(obj1).length != Object.keys(obj2).length) return false
    for (var prop in obj1) {
      if (obj2.hasOwnProperty(prop)) {
        if (Array.isArray(obj1[prop]) && Array.isArray(obj2[prop])) {
          // If both values are arrays, sort them and compare
          if (!isArraysEqual([...obj1[prop]].sort(), [...obj2[prop]].sort()))
            return false
        } else {
          // If the values are not arrays, use the original comparison function
          if (!isObjectsEqual(obj1[prop], obj2[prop])) return false
        }
      } else {
        return false
      }
    }
    return true
  } else {
    return false
  }
}

export function isArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (!isObjectsEqual(arr1[i], arr2[i])) return false
  }
  return true
}

export function getQueryString(url) {
  return url?.substring(url?.indexOf("?") + 1)
}

export function deepCopyObject(obj) {
  if (
    typeof obj !== "object" ||
    obj === null ||
    obj.$$typeof === Symbol.for("react.element") // in case of jsx elements
  ) {
    return obj
  }

  const copiedObject = obj instanceof Array ? [] : {}
  for (const key in obj) {
    copiedObject[key] = deepCopyObject(obj[key])
  }

  return copiedObject
}

/**
 * Extracts query parameters from a URL or a query string.
 *
 * @param {string} urlString - The URL or query string.
 * @returns {Record<string,string>} An object containing the query parameters as key-value pairs.
 *
 * @example
 * // returns { param1: "value1", param2: "value2" }
 * getQueryParams("http://example.com/page?param1=value1&param2=value2");
 *
 * @example
 * // returns { param1: "value1", param2: "value2" }
 * getQueryParams("param1=value1&param2=value2");
 *
 * @example
 * // returns {}
 * getQueryParams("http://example.com/page");
 */
export function getQueryParams(urlString) {
  const queryString = urlString.includes("?") ? urlString.split("?")[1] : urlString

  if (!queryString) return {}

  const pairs = queryString.split("&")
  const queryParams = pairs.reduce((acc, pair) => {
    const [key, value] = pair.split("=")
    acc[key] = value
    return acc
  }, {})

  return queryParams
}

function parseQueryParamValue(value = "") {
  // If the value contains commas, split it into an array
  if (value.includes(",")) {
    return value.split(",")
  }
  return value
}

/**
 * @deprecated This should be updated with new getQueryParams , as this will cause issue with (&,)
 */
export function getQueryParamsFromUrl(url = "") {
  if (url?.includes("?")) [, url] = url.split("?")
  const searchParams = new URLSearchParams(url)
  const queryParams = {}
  for (const pair of searchParams.entries()) {
    const [key, value] = pair
    queryParams[key] = parseQueryParamValue(value)
  }
  return queryParams
}

export function getParsedDomFromMDString(markdownString) {
  try {
    const parser = new DOMParser()
    const dom = parser.parseFromString(
      converter.makeHtml(markdownString),
      "text/html"
    )
    return dom
  } catch (error) {
    console.log(error)
  }
}

export function getDocumentTitleFromMD(markdownString) {
  const doc = getParsedDomFromMDString(markdownString)
  const nodes = doc.querySelectorAll("h1,h2")
  const text = nodes.length > 0 ? nodes[0].innerText : ""
  return text
}

export function getMdStringFromHTML(htmlString) {
  if (!htmlString) return
  return converter.makeMarkdown(htmlString)
}

export const isFloat = (x) => !!(x % 1)

export const mapObjectToArray = (object = {}, sortKey) => {
  const results = []
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key]
      results.push({ ...element, key })
    }
  }
  if (sortKey) {
    results.sort((a, b) => {
      if (typeof a[sortKey] === "string" && typeof b[sortKey] === "string") {
        return a[sortKey].localeCompare(b[sortKey])
      }
      return 0
    })
  }
  return results
}

/**
 * Checks if the given parameter is an array.
 * @param {any} array - The parameter to be checked.
 * @returns {array is Array<any>} Returns true if the parameter is an array, otherwise false.
 */
export const isArray = (array) => Array.isArray(array)

/**
 * This function checks if a given object is empty. It considers an object as empty if it is null or undefined,
 * if it's an array with no elements, or if it's an object with no properties.
 *
 * @param {Object | Array<any> | null | undefined} testObject - The object or array to check.
 * @returns {testObject is undefined} - Returns true if the input is null, undefined, an empty array, or an empty object. Otherwise, it returns false.
 */
export function isNullOrEmpty(testObject) {
  if (!testObject) return true
  if (testObject instanceof Array) {
    return testObject.length == 0
  }
  return Object.keys(testObject).length == 0
}

/**
 * Checks if an object or array is empty.
 * @param {Object | Array | undefined | null} obj - The object or array to check.
 * @returns {boolean} - Returns `true` if the object or array is empty, otherwise returns `false`.
 */
export function isEmpty(obj) {
  if (Array.isArray(obj)) {
    return obj.length == 0
  }
  return obj !== null && typeof obj === "object" && Object.keys(obj).length == 0
}

/**
 * Callback for filterObjects.
 *
 * @template T
 * @callback FilterCallback
 * @param {T} object - The current property's value.
 * @param {string} key - The current property's key.
 * @returns {boolean} Return true to keep the property, false otherwise.
 */

/**
 * Filters properties of an object based on a callback predicate.
 *
 * @template T
 * @param {Record<string, T>} objects - The object to be filtered.
 * @param {FilterCallback<T>} cb - Callback to test each property.
 * @returns {Record<string, T>} New object with properties that passed the callback test.
 */
export function filterObjects(objects, cb) {
  const inputObjects = deepCopyObject(objects)
  let newObjects = {}
  for (const key in inputObjects) {
    if (Object.hasOwnProperty.call(inputObjects, key)) {
      if (cb(inputObjects[key], key)) {
        newObjects = { ...newObjects, [key]: inputObjects[key] }
      }
    }
  }
  return newObjects
}

export function filterUndefinedFromObject(obj) {
  function recursiveFilter(value) {
    if (Array.isArray(value)) return value.map(recursiveFilter)
    else if (isObject(value)) {
      const filteredObj = {}
      Object.keys(value).forEach((key) => {
        if (value[key] !== undefined) {
          filteredObj[key] = recursiveFilter(value[key])
        }
      })
      return filteredObj
    }
    return value
  }
  return recursiveFilter(obj)
}

/**
 * Checks if the provided value is a function.
 *
 * @param {any} value - The value to check.
 * @returns {object is Function}
 */
export function isFunction(object) {
  return typeof object === "function"
}

export function isString(str) {
  return typeof str == "string"
}

export function isNumber(num) {
  return typeof num == "number"
}

/**
 * Checks if a value is a plain object.
 *
 * This function determines if the provided value is an object created by the
 * object literal notation `{}` or by `new Object()`. It does not consider
 * instances from null, arrays, functions, or any other built-in object types
 * in JavaScript as plain objects.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} `true` if the value is an object, `false` otherwise.
 */
export function isObject(value) {
  if (typeof value !== "object" || value == null) {
    return false
  }
  return Object.getPrototypeOf(value) === Object.prototype
}

export function mergeObjects(object1 = {}, object2 = {}) {
  const mergedObject = { ...object1 }

  for (const key in object2) {
    if (Object.prototype.hasOwnProperty.call(object2, key)) {
      if (Array.isArray(object1[key]) && Array.isArray(object2[key]))
        mergedObject[key] = [...object1[key], ...object2[key]]
      else mergedObject[key] = object2[key]
    }
  }
  return mergedObject
}

/*
 * obj = {
 *  ids: ['TAG_corsair-crossmarketplace'],
 *  cat_id: ['diy'],
 *  label_sub_cat: ['diy', 'corsair_hid'],
 *  time: ['1yr']
 * }
 *
 * list = ["ids", "time"]
 *
 * returns {cat_id: ['diy'], label_sub_cat: ['diy', 'corsair_hid']}
 */
export function deleteKeysFromObject(obj, keysToRemove) {
  const newObj = { ...obj }
  keysToRemove.forEach((key) => {
    delete newObj[key]
  })
  return newObj
}

/**
 *
 * @param {Object} target
 * @param {Object} source
 * @returns {Object}
 *
 * @description
 * Ignores array merge, if the prop is an array then with equal depth then @source prop will overwrite the @target
 *
 * @example
 * const target = { a: 1, b: [2, 3] };
 * const source = { b: [4, 5], c: 6 };
 * const result = deepMerge(target, source);
 * Result: { a: 1, b: [4, 5], c: 6 }
 */
export function deepMerge(target = {}, source = {}) {
  const result = { ...target }
  if (!isObject(result) || !isObject(source)) {
    return source
  }

  for (const key in source) {
    if (isObject(source[key])) {
      if (!target[key]) target[key] = {}
      result[key] = deepMerge(target[key], source[key])
    } else {
      result[key] = source[key]
    }
  }
  return result
}

export function recordErrorInForm(displayMessage, internalMessage) {
  const serializedInternalMessage =
    typeof internalMessage === "object"
      ? JSON.stringify(internalMessage)
      : internalMessage
  //🪧 For debugging in local
  if (process.env.NODE_ENV === "development" && serializedInternalMessage) {
    console.log("displayMessage", displayMessage)
    console.log("serializedInternalMessage", serializedInternalMessage)
  }
  typeof window !== "undefined" &&
    hitUrl(
      `https://docs.google.com/forms/d/e/1FAIpQLSefxiLTW4dTdeINkz4_7bWrmmo41f3fTAqvg7ijT0mtEcvBog/formResponse?usp=pp_url&entry.653933717=${location?.href?.replaceAll(
        "&",
        "**"
      )}&entry.1802170692=${`${displayMessage} \n ${serializedInternalMessage}`}&submit=Submit`
    )
}

export function hitUrl(url) {
  url && process.env.NODE_ENV === "production" && fetch(url)
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function getRandomRGBColor(opacity = 1.5) {
  // 🚦 opacity 0 to 10
  return `rgb(${getRandomNumber(0, 255)} ${getRandomNumber(
    0,
    255
  )} ${getRandomNumber(0, 255)} / ${opacity * 10}%)`
}

export function formatAsUsLocale(number) {
  //🚦example: 150000 👉 150,000
  if (!number) return
  const roundedNumber = Math.round(Math.abs(number) * 10) / 10
  return new Intl.NumberFormat("en-US").format(roundedNumber)
}

/**
 * Inserts new items into an existing array at a specified position.
 *
 * @template T
 * @param {Array<T>} existingArray - The original array.
 * @param {Array<T>} newItems - Items to be inserted.
 * @param {number} position - Insertion index in the array.
 * @returns {Array<T>} New array with items inserted.
 *
 * @example
 * Inserts [5, 6] into [1, 2, 3, 4] at index 2, resulting in [1, 2, 5, 6, 3, 4]
 * insertAtPosition([1, 2, 3, 4], [5, 6], 2);
 */

export function insertItemsAtPosition(existingArray, newItems, position) {
  const result = existingArray.slice()
  result.splice(position, 0, ...newItems)
  return result
}
