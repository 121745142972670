import { useRouter } from "next/router"
import { getDecodedURIComponent, replaceAll } from "./util.js"
import { ranges } from "@common/datePickers/dateRangePicker.js"
import { getPathWithSentiment } from "./navigation.js"
import { removeQueryParams } from "components/common/lib/navigation"
import { isArray, isNullOrEmpty } from "components/common/lib/util"
import { getDateWithSeparator } from "@common/datePickers/utils"
import { removeInvalidQueryParams } from "@common/lib/navigation"

// TODO - Cleanup.
// Only functions that act on filtersState or support the filter component should be here. Any function that takes path/router/query or returns path should be in navigation.js.

export function getUrlQueryString(router) {
  return router.asPath.split("?")[1]
}
export function getFiltersFromPath(path) {
  return path.split("?")[1]
}

export function getWidgetId(router) {
  const { widget_id } = router.query
  return widget_id
}

export function getCustomerId() {
  const router = useRouter()
  return router.query.customerId
}
export function getDocId() {
  const router = useRouter()
  return router.query.doc_id
}
export function getCategoryId() {
  const router = useRouter()
  const { cat_id } = router.query
  if (!cat_id) return
  const catId = decodeURIComponent(cat_id)
  if (typeof catId === "string") return catId?.replace(" ", "+")
  if (isArray(catId)) return catId[0]?.replace(" ", "+")
}
export function getReportId() {
  const router = useRouter()
  const { report_id } = router.query
  return report_id
}
export function getEntityId() {
  const router = useRouter()
  if (!router.query || !("ids" in router.query)) return

  let entityId = router.query.ids.split(",")[0]
  entityId = replaceAll(entityId, " ", "+")
  return entityId
}

export function getEntityIds() {
  const router = useRouter()
  if (!router.query || !("ids" in router.query)) return
  const entityIds = router.query.ids.split(",")
  const entities = []
  for (const idx in entityIds) {
    entities.push(replaceAll(entityIds[idx], " ", "+"))
  }
  return entities
}

// queries - > time, ids,
/**
 * removeQueryStringFromPath(path, "time", "ids", "sent")
 *
 */
export function removeQueryStringFromPath(path, ...queries) {
  if (path.indexOf("?") == -1) return path
  let newPath = path
  queries.forEach((queryKey) => {
    newPath = removeQueryParams(newPath, queryKey)
  })
  return newPath
}

export function getFiltersForComments(filters, overrideEntityId) {
  const commentFilters = []
  if (!filters || filters.indexOf("&") == -1 || filters.indexOf("ids=") == -1) {
    commentFilters.push(`ids=${overrideEntityId}`)
  }
  const queries = filters?.split("&")
  queries?.forEach((query) => {
    const [filterKey] = query.split("=")
    if (filterKey == "ids" && overrideEntityId)
      commentFilters.push(`ids=${overrideEntityId}`)
    else if (isCommentFilters(filterKey)) {
      commentFilters.push(query)
    }
  })
  return commentFilters.join("&")
}

export function isCommentFilters(filterKey) {
  if (
    filterKey == "sort" ||
    filterKey == "senti" ||
    filterKey == "text" ||
    filterKey == "ids"
  )
    return true
  return false
}

export function addFilter(path, filterKey, filterValue) {
  if (!filterValue) return path
  let newPath = path
  if (path.indexOf("?") != -1) {
    newPath = removeQueryParams(path, filterKey)
  }
  newPath = addFilterIfNotPresent(newPath, filterKey, filterValue)
  return newPath
}

export function removeFilterFromQuerystring(querystring, filterKey) {
  if (!querystring) return
  const path = "?" + querystring
  const newPath = removeQueryParams(path, filterKey)
  let filters = getFiltersFromPath(newPath)
  if (filters[0] == "&") filters = filters.substring(1)
  return filters
}

export function addFilterIfNotPresent(oldPath, filterKey, filterValue) {
  if (
    oldPath.indexOf(`?${filterKey}`) != -1 ||
    oldPath.indexOf(`&${filterKey}`) != -1
  )
    return oldPath
  if (oldPath.indexOf("?") == -1) {
    return oldPath + `?${filterKey}=${filterValue}`
  } else return oldPath + `&${filterKey}=${filterValue}`
}

// TODO - ideally rely on config
export function isCommonFilter(filterKey) {
  if (
    filterKey == "ids" ||
    // filterKey == "time" ||
    // filterKey == "sort" ||
    filterKey == "text" ||
    // filterKey == "senti" ||
    filterKey == "compare" ||
    filterKey == "report_id" ||
    filterKey == "cat_id" ||
    filterKey == "selected_aspect"
  )
    return true
}

// returns current path minus the filter specified in argument
export function replaceFilter(oldPath, filterKey, newValue) {
  if (!oldPath) return ""
  let newPath = removeQueryParams(oldPath, filterKey)

  if (!newValue) return newPath

  if (newPath.indexOf("?") == -1) newPath += `?${filterKey}=${newValue}`
  else if (newPath.charAt(newPath.length - 1) === "?")
    newPath += `${filterKey}=${newValue}`
  else newPath += `&${filterKey}=${newValue}`

  return newPath
}

export function replaceFilterInQuerystring(querystring, filterKey, newValue) {
  if (!querystring && !newValue) return

  const path = "?" + (querystring ? querystring : "")
  const newPath = replaceFilter(path, filterKey, newValue)

  let filters = getFiltersFromPath(newPath)
  if (!filters) return newPath

  if (filters[0] == "&") filters = filters.substring(1)

  return filters
}

// todo - move to check in query
export function isFilterInPath(path, filterKey) {
  if (!path) return
  return path.toString().indexOf(filterKey) != -1
}

export function isFilterInQueryPath(query, filterKey) {
  if (Object.keys(query).length == 0) return
  return filterKey in query
}

export function getFilterValue(query, filterKey) {
  if (isNullOrEmpty(query) || !filterKey) return
  const value = query[filterKey]
  if (value) return value //decodeURIComponent(value)
  return
}

// input - time, time=1mon&stars=1,2,3
// output - 1mon
// returns last occurance
export function getFilterValueInQuerystring(querystring, filterKey) {
  if (!querystring) return
  const parts = querystring.split("&")
  let value = null
  for (const i in parts) {
    const keyValue = parts[i].split("=")
    if (keyValue[0] == filterKey) value = keyValue[1]
  }
  return value
}

export function getFilterCount(state) {
  let count = 0
  for (const key in state) {
    //if (isCommonFilter(key)) continue
    if (key === "time") count++
    else count += state[key].length
  }
  return count
}

// onlyConfigKeys -> true -> Include keys whitelisted by backend filters object.
// onlyNonConfigKeys -> true -> Include keys other than those whitelisted by backend filters object.
export function getFiltersStateFromUrl(
  path,
  config,
  onlyConfigKeys = true,
  onlyNonConfigKeys = false
) {
  let filtersState = {}
  if (!path || path.indexOf("?") == -1)
    //2nd check required since router returns incomplete path on serverside
    return filtersState

  // path = decodeURIComponent(path)
  const filters = getUrlParts(path)[1].split("&")

  filtersState = getFiltersStateFromFilters(
    filters,
    config,
    onlyConfigKeys,
    onlyNonConfigKeys
  )
  return filtersState
}

// input = ["time=1mon", "ids=xyz", "stars=1,2,3"]
// output = {'time': ['1mon'], ids: ['xyz'], stars: [1,2,3]}
export function getFiltersStateFromFilters(
  filters,
  config,
  onlyConfigKeys = true,
  onlyNonConfigKeys = false
) {
  const filtersState = {}

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]

    if (!filter) continue

    const filterParts = filter.split("=")
    const [filterKey, filterValue] = filterParts

    if (onlyConfigKeys && config && !(filterKey in config)) continue
    if (onlyNonConfigKeys && config && filterKey in config) continue

    /* Backend can send "" or null as values in onSelect
     * Hence, state needs to maintain this state, so that it can be handled.
     * Removal of invalid query params can happen at the url construction layer. */
    if (!filterValue) {
      filtersState[filterKey] = filterValue
      //continue // in case of invalid query
    }
    //decoding each query value before putting in filterState
    if (onlyConfigKeys && config && filterKey in config) {
      if (config[filterKey].type == "range" && filterValue?.indexOf("-") != -1)
        filtersState[filterKey] = filterValue.split("-")
      else {
        filtersState[filterKey] = filterValue
          ?.split(",")
          .map((v) => getDecodedURIComponent(v))
      }
    }

    if (onlyNonConfigKeys) {
      filtersState[filterKey] = filterValue
        ?.split(",")
        .map((v) => getDecodedURIComponent(v))
    }
  }

  return filtersState
}

export function getUrlFromFiltersState(oldPath, filtersState, config) {
  const pathFiltersState = getFiltersStateFromUrl(oldPath, config, false, true)
  const updatedFiltersState = { ...pathFiltersState, ...filtersState }
  const querystring = getFiltersQuerystringFromFiltersState(
    updatedFiltersState,
    config
  )

  return getUrlFromQuerystring(oldPath, querystring)
}

export function getUrlFromQuerystring(oldPath, querystring) {
  const newPath = getUrlParts(oldPath)[0]
  //return `${newPath}?${querystring}`
  return removeInvalidQueryParams(`${newPath}?${querystring}`)
}

export function getFiltersQuerystringFromFiltersState(filtersState, config) {
  let querystring = ""
  for (const filter in filtersState) {
    const filterValues = filtersState[filter]
    if (filterValues.length > 0) {
      querystring += filter + "="
      for (let i = 0; i < filterValues.length; i++) {
        if (config && config[filter] && config[filter].type == "range")
          querystring += filterValues[i] + "-"
        else querystring += encodeURIComponent(filterValues[i]) + ","
      }
      // strip last , if present
      if (
        querystring[querystring.length - 1] == "," ||
        querystring[querystring.length - 1] == "-"
      )
        querystring = querystring.substring(0, querystring.length - 1)

      // add &
      querystring += "&"
    }
  }
  // remove last & if present
  if (querystring[querystring.length - 1] == "&")
    querystring = querystring.substring(0, querystring.length - 1)
  return querystring
}

export function getTimeFilterDisplay(filtersState) {
  if (!filtersState) return "..."

  if (!("time" in filtersState) || filtersState["time"].length == 0)
    return "All Time"

  if (filtersState["time"].length == 1) {
    return filtersState["time"][0] in ranges &&
      "label" in ranges[filtersState["time"][0]]
      ? ranges[filtersState["time"][0]].label
      : filtersState["time"][0]
  }

  const str =
    getDateWithSeparator(filtersState["time"][0]) +
    " - " +
    getDateWithSeparator(filtersState["time"][1])

  return str
}

function getUrlParts(url) {
  return url.split("?")
}

// time manipulation
export function replaceTime(path, newTime) {
  if (!path || !newTime) return path

  let newPath = removeQueryParams(path, "time")
  newPath = replaceFilter(newPath, "time", newTime)
  newPath = addFilterIfNotPresent(newPath, "time", newTime)
  return newPath
}

// replace the filters with query overides and returns a new path with query overides
export function overrideQuery(path, query_override) {
  let newPath = path
  const queryParts = query_override.split("&")
  for (let i = 0; i < queryParts.length; i++) {
    const parts = queryParts[i].split("=")
    const [queryKey, queryValue] = parts
    newPath = replaceFilter(newPath, queryKey, queryValue)
  }
  return newPath
}

// path = /d/corsair/diy/stats
export function replaceCategory(path, query, newCategory) {
  if (!path || !newCategory) return path
  return addFilter(path, "cat_id", newCategory)
}

export function replaceText(path, newText, newSenti) {
  if (!path || !newText) return path
  const newPath = replaceFilter(path, "text", newText)
  return getPathWithSentiment(newPath, newSenti)
}

export function removeAspects(path) {
  let newPath = removeQueryParams(path, "aspect")
  newPath = removeQueryParams(newPath, "text")
  newPath = removeQueryParams(newPath, "senti")
  return newPath
}

export function textURLFriendly(text) {
  /*
  check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
  below code filters slashes
  */
  return encodeURIComponent(text.replace(/(\\|\/)/g, "").replace(/"/g, '\\"'))
}
/* end: text and aspect manipulation */

export function filterObjectByKeyValue(obj, key, value) {
  // ---> SEARCHES OBJECT FOR A SPECIFIC VALUE AGAINST A SPECIFIC KEY
  if (!obj) return null
  const filteredArray = []
  Object.keys(obj).filter((i) => {
    if (obj[i][key] == value) {
      filteredArray.push(obj[i])
    }
  })
  return filteredArray
}

export function isFilterPresent(query, filterKey) {
  if (!query || Object.keys(query).length == 0) return false
  if (filterKey in query) return true
  return false
}

export function appendQuery(queryString, queryKey, queryValue) {
  const searchParams = new URLSearchParams(queryString)
  if (queryKey && queryValue) searchParams.append(queryKey, queryValue)
  return searchParams.toString()
}

export function isDashboardEditable(router) {
  const { isEditable } = router.query
  return isEditable === "true"
}

export function getFiltersStateFromQueryString(
  queryString = "",
  filtersConfig,
  onlyConfigKeys,
  onlyNonConfigKeys
) {
  if (queryString.includes("?")) [, queryString] = queryString.split("?")
  const filters = queryString.split("&")
  return getFiltersStateFromFilters(
    filters,
    filtersConfig,
    onlyConfigKeys,
    onlyNonConfigKeys
  )
}
