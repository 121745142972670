import { getCustomerId } from "lib/filters.js"
import { getQuota } from "network/network.js"
import { round } from "lib/util"
import { useTheme } from "next-themes"

export default function useQuota() {
  const customerId = getCustomerId()
  const { quota, isQuotaLoading, isQuotaError } = getQuota(customerId)
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"

  function getPropertyValue(quota, property) {
    return quota?.products?.length > 0 &&
      property in quota.products[0]
      ? quota.products[0][property]
      : null
  }

  const total = getPropertyValue(quota, "total")
  const consumed = getPropertyValue(quota, "consumed")

  const getPercentage = () => {
    if (total !== null && consumed !== null) {
      const percentage = round((consumed / total) * 100, 0) || 0
      return percentage
    } else {
      return null
    }
  }

  const getPending = () => {
    if (total !== null && consumed !== null) {
      const pending = total - consumed || null
      return pending
    } else {
      return null
    }
  }

  const percentage = getPercentage()
  const pending = getPending()
  const expiry = getPropertyValue(quota, "expires_ts")

  return {
    quota,
    isQuotaLoading,
    isQuotaError,
    total,
    consumed,
    percentage,
    pending,
    expiry,
    isDarkMode,
  }
}