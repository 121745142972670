import { useCallback, useState, useEffect, useRef } from "react"
import useSWRInfinite from "swr/infinite"
// import fetch from "node-fetch"
import {
  replaceFilter,
  isFilterInPath,
  replaceFilterInQuerystring,
  getFiltersForComments,
} from "./filters.js"
import { getApiURL, getHashFromObject, removeAllSlashes } from "./util.js"
import { getFullUrl, postFetcher } from "network/utils.js"
import { getSwr, postSwr } from "network/networkInterface.js"
import { isNullOrEmpty } from "@common/lib/util"

export function useFilters(categoryId, entityId, reportId, overrideQuery, demo) {
  let query = ""
  const details = {}

  if (categoryId && entityId) {
    // CASE for getting filtes in Template reports
    query = `ids=${entityId}&cat_id=${categoryId}`
  } else if (overrideQuery) {
    // CASE for getting dynamic subfilters (entire query string is sent)
    query = overrideQuery
  } else if (reportId) {
    // CASE for getting filtes in Instance reports
    details["report_id"] = reportId
  }
  // Case for demo reports
  if (demo) query += query ? `&demo=true` : `demo=true`
  if (query) details["query"] = query
  const shouldFetch =
    !!(reportId || (categoryId && entityId) || overrideQuery) &&
    !isNullOrEmpty(details)
  const { data, isLoading, isError, message, status, mutate } = postSwr(
    `api_v2/get_filters/filters`,
    details,
    shouldFetch,
    false,
    true
  )

  return {
    filters: data,
    isLoading,
    isError,
    mutate,
    message,
    status,
  }
}

// send both ids and catId
export function fetchEntity(
  customerId,
  ids,
  catId,
  shouldFetch = true,
  shouldMutate = false,
  demo
) {
  const fetch =
    customerId != undefined &&
    ids != undefined &&
    catId != undefined &&
    shouldFetch == true
  const { entities, isEntitiesLoading, isEntitiesError } = fetchEntityList(
    customerId,
    ids,
    catId,
    null,
    null,
    null,
    null,
    null,
    fetch,
    shouldMutate,
    null,
    demo
  )

  return {
    entity: entities ? Object.values(entities)[0] : {},
    isEntityLoading: isEntitiesLoading,
    isEntityError: isEntitiesError,
  }
}

// type -> Optional. e.g  type = entities, manual
// cat_id / ids -> both have to be provided. only 1 won't work.
// expand=true -> Used to get the components of a group. (in manage -> groups -> edit group flow)
export function fetchEntityList(
  customerId,
  ids,
  catId,
  sourceId,
  reportId,
  numResults = 100,
  text,
  type,
  shouldFetch = true,
  shouldMutate = false,
  expand = false,
  demo
) {
  const encodedText = text ? encodeURIComponent(removeAllSlashes(text)) : null

  const apiUrl = getApiURL({
    customerId,
    type,
    ids,
    catId,
    text: encodedText,
    numResults,
    sourceId,
    reportId,
    expand,
    demo,
  })

  const fetch = customerId != undefined && shouldFetch == true

  const { data, isLoading, isError, message } = getSwr(
    apiUrl,
    fetch,
    null,
    shouldMutate
  ) //{revalidateOnMount: true})

  return {
    entities: data,
    isEntitiesLoading: isLoading,
    isEntitiesError: isError,
    message: message,
  }
}

export function useFetchMetricData(
  customerId,
  filters,
  details,
  shouldMutate = false,
  shouldFetch = true
) {
  details["query"] = decodeURIComponent(filters)
  const widgetId = details.widget_id
  const query = widgetId || details.query_type
  const apiUrl = `api_v2/query/${customerId}/${query}`
  const isFetch = shouldFetch == true && customerId != undefined
  const { data, isLoading, isError, isValidating, mutate, message, status } =
    postSwr(apiUrl, details, isFetch, shouldMutate, true)
  return {
    data,
    isLoading,
    isError,
    isValidating,
    mutate,
    message,
    status,
  }
}

// TODO - repalce with new function
export function fetchKeywordSuggestions(
  customerId,
  categoryId,
  filters,
  textQueryOverride
) {
  //UPDATING QUERY STRING
  let queryString = filters
  const text = encodeURIComponent(removeAllSlashes(textQueryOverride))
  queryString = textQueryOverride
    ? replaceFilterInQuerystring(queryString, "text", text)
    : queryString

  //MAKE PAYLOAD
  const details = {
    query: decodeURIComponent(queryString),
    query_type: "search_suggestions",
  }
  const apiUrl = `api_v2/query/${customerId}/${categoryId}/search_suggestions`
  const shouldFetch = customerId && categoryId && filters
  const { data, isLoading, isError, message, status } = postSwr(
    apiUrl,
    details,
    shouldFetch,
    false,
    true
  )
  return {
    keywords: data,
    isKeywordsLoading: isLoading,
    isKeywordsError: isError,
    message,
    status,
  }
}
/**
 *
 * @param {string} textQueryOverride -> overrides the text query present in the url.
 * @param {boolean} useCommentFilters -> Used in the case of "topics creation - preview"
 * when filters other than commentsUnit specific filters shouldn't be sent to backend.
 *
 */
export function useFetchComments(
  queryType,
  customerId,
  categoryId,
  filters,
  count,
  timeOverrideStr,
  isDarkMode,
  textQueryOverride,
  useCommentFilters
) {
  //UPDATING QUERY STRING
  const newFilters = useCommentFilters
    ? getFiltersForComments(filters, "all")
    : filters

  let queryString = newFilters
  queryString = textQueryOverride
    ? replaceFilterInQuerystring(queryString, "text", textQueryOverride)
    : queryString
  queryString += `&count=${count}`

  if (timeOverrideStr) {
    queryString = replaceFilter(queryString, "time", timeOverrideStr)
  }
  if (isFilterInPath(queryString, "sort") == false) {
    queryString += `&sort=sentiment`
  }
  if (isDarkMode) {
    queryString += `&graph_dark_mode=${isDarkMode}`
  }

  //MAKE PAYLOAD
  const details = { query: decodeURIComponent(queryString), query_type: queryType }
  const detailsHash = getHashFromObject(details)

  //CONSTRUCT URL
  const apiUrl = `api_v2/query/${customerId}/${categoryId}/${queryType}_${detailsHash}`
  const url = getFullUrl(apiUrl)

  const fetcher = ({ url, index }) =>
    postFetcher(
      url,
      JSON.stringify({
        ...details,
        query: `${details.query}&start=${index * count}`,
      })
    )

  // let shouldFetch = customerId && categoryId && filters

  // TODO - Since comments has "Load more", it is not implemented as a true infinite scroll.
  // This could be done using the basic useSWR query
  // Either change this to useSWR or change the implementation to proper infinite scroll
  const { data, error, size, setSize } = useSWRInfinite(
    (index) => ({ url, index }),
    fetcher,
    { revalidateOnFocus: false, revalidateFirstPage: false }
  )

  const firstData = data?.[0]?.data
  const lastData = data?.[data.length - 1]?.data
  const lastRecords = lastData?.records

  const isError = error || lastData?.status == "ERROR"
  const isLoading = !error && !data
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1]?.data === "undefined")
  const isEmpty = firstData?.length === 0 //|| firstData?.records?.length == 0
  const isEnd = isEmpty || lastRecords?.length < count || lastRecords?.length == 0

  /**
   * NOTE: "data" & "message" is being manipulated
   *       as custom fetchers (in networkInterface) manipulates data into standard output structure
   *       & that standard output structure is received here intead of
   *       data directly as it was earlier (before this change).
   *
   * TODO: move this to a custom pagination inplementation to get rid of the complex data structure
   */
  return {
    data: data?.map((page) => page.data)?.filter(Boolean),
    size: size,
    setSize: setSize,
    isLoading: isLoading,
    isLoadingMore: isLoadingMore,
    isEmpty: isEmpty,
    isEnd: isEnd,
    isError: isError,
    errorMessage: lastData?.error_message || "Could not fetch data.",
    message: data?.map((page) => page.message),
  }
}

export function useProducts(customerId) {
  const category = "all" // categoryId
  const apiUrl = `api/insights/get_product_id_meta/${customerId}/${category}`
  const url = getFullUrl(apiUrl)

  const { data, isError, isLoading, message, status } = getSwr(
    url,
    true,
    null,
    false
  )

  return {
    data,
    isLoading,
    isError,
    message,
    status,
  }
}

export function usePreviousValue(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted
}

export function useIsMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return useCallback(() => isMounted.current, [])
}

export const useWindowEvent = (event, callback, bool) => {
  useEffect(() => {
    window.addEventListener(event, callback, bool)
    return () => window.removeEventListener(event, callback, bool)
  }, [event, callback, bool])
}

export const useCustomDomEvent = (event, div, callback, bool) => {
  useEffect(() => {
    div.addEventListener(event, callback, bool)
    return () => div.removeEventListener(event, callback, bool)
  }, [event, div, callback])
}

export const useConfig = ({
  configName,
  fallbackConfigName,
  fallbackConfig, // actual config
}) => {
  const [finalConfigName, setFinalConfigName] = useState()
  const [config, setConfig] = useState()
  const [isLoading, setIsLoading] = useState(false)
  if (!configName) return
  const initConfig = async () => {
    setConfig(null)
    setFinalConfigName(null)
    setIsLoading(true)
    try {
      const data = await import(`../configs/${configName}.json`)
      setConfig(data["default"])
      setFinalConfigName(configName)
      setIsLoading(false)
    } catch (err) {
      try {
        const data = await import(`../configs/${fallbackConfigName}.json`)
        setConfig(data["default"])
        setFinalConfigName(fallbackConfigName)
        setIsLoading(false)
      } catch (err) {
        if (fallbackConfig) setConfig(fallbackConfig)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    initConfig()
  }, [configName, fallbackConfigName])

  return [config, isLoading, finalConfigName]
}

export async function initConfig(
  configName,
  setConfig,
  fallbackConfig,
  fallbackConfigName
) {
  try {
    const data = await import(`../configs/dashboard/${configName}.json`)
    setConfig(data["default"])
  } catch (err) {
    try {
      const data = await import(`../configs/dashboard/${fallbackConfigName}.json`)
      setConfig(data["default"])
    } catch (err) {
      if (fallbackConfig) setConfig(fallbackConfig)
    }
  }
}
