export enum LoginTypeEnum {
  "GoogleLogin",
  "Login",
  "Magiclink",
}

export enum SettingsEnum {
  "general",
  "email",
  "appearance",
}
