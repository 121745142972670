// PROJECT AGNOSTIC REUSABLE COMMON NAVIGATION FUNCTIONS GO HERE

import { isNullOrEmpty } from "@common/lib/util"

// Function returns the path with only the params specified in the params list and deletes all other parameters
// Input -
//   - path -> https://test.com?param1=val1&param2=val2&param3=val3
//   - params -> [param1, param3]
// Output - https://test.com?param2=val2
export function getPathWithParams(path: string, params: string | string[]): string {
  params = Array.isArray(params) ? params : [params]
  const [base, query] = path.split("?")

  if (!query) return path

  const searchParams = new URLSearchParams(query)
  const newSearchParams = new URLSearchParams()

  // set the parameters that are in the params list
  for (const p of params) {
    if (searchParams.has(p)) {
      newSearchParams.set(p, searchParams.get(p) as string)
    }
  }

  return `${base}?${newSearchParams.toString()}`
}

export function getPreviousPath(path: string) {
  if (path) {
    const [pathWithoutQuery, queryParams] = path.split("?")
    const parts = pathWithoutQuery.split("/")
    parts.pop()
    let newPath = parts.join("/")

    // Add the query parameters back to the path
    if (queryParams) {
      newPath += "?" + queryParams
    }
    return newPath
  }
}

// input - ?q1=val1&q2=val2&q3=val3 OR q1=val1&q2=val2&q3=val3
// output - q1=val1&q3=val3 (if q2 has to be removed)
export function removeQueryParamsFromQuerystring(
  querystring: string,
  params: string | string[]
) {
  if (!querystring || isNullOrEmpty(params)) return querystring

  if (querystring.indexOf("?") == -1)
    return removeQueryParams(`?${querystring}`, params).substring(1)
  else if (querystring.indexOf("?") == 0)
    return removeQueryParams(querystring, params).substring(1)
}

export function removeQueryParams(url: string, parameter: string | string[]) {
  if (!url) return ""

  const removeParameter = (url: string, parameter: string): string => {
    // path is passed without querystring
    if (url.indexOf("=") === -1) return url

    //Full url is passed
    if (url.includes("?")) {
      const [path, querystring] = url.split("?")
      const prefix = encodeURIComponent(parameter) + "="
      const pars = querystring.split(/[&;]/g)
      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1)
        }
      }
      return path + (pars.length > 0 ? "?" + pars.join("&") : "")
    } else {
      const prefix = encodeURIComponent(parameter) + "="
      const pars = url.split(/[&;]/g)
      for (let i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1)
        }
      }
      return pars.join("&")
    }
  }

  if (typeof parameter === "string") {
    return removeParameter(url, parameter)
  } else if (Array.isArray(parameter)) {
    let newPath = url
    parameter.forEach((param) => {
      newPath = removeParameter(newPath, param)
    })
    return newPath
  }
  return url
}

// Merge query string params while prioritizing params from the first query string
export function mergeQueryStrings(queryString1: string, queryString2: string) {
  const params1 = new URLSearchParams(queryString1)
  const params2 = new URLSearchParams(queryString2)

  params2.forEach((value, key) => {
    if (!params1.has(key)) {
      params1.set(key, value)
    }
  })

  return params1.toString()
}

/*
 * Example usage:
 * const url1 = "freetext.ai/d/corsair_prod/stats?ids=TAG_corsair-crossmarketplace&time=1yr&cat_id=corsair_all&report_id=super_category&label_sub_cat="
 * const url2 = "freetext.ai/d/corsair_prod/stats?ids=TAG_corsair-crossmarketplace&time=1yr&cat_id=corsair_all&label_sub_cat=&report_id=super_category"
 *
 * Returns url without label_sub_cat= in both cases
 */
export function removeInvalidQueryParams(url: string): string {
  const urlParts = url.split("?")

  if (urlParts.length === 2) {
    const [baseUrl, queryString] = urlParts
    const queryParams = queryString.split("&")

    const validQueryParams = queryParams.filter((param) => {
      const [key, value] = param.split("=")
      return value !== "" && value !== "null" && value !== "undefined"
    })

    if (validQueryParams.length > 0) {
      const newQueryString = validQueryParams.join("&")
      return trimTrailingSpecialChars(`${baseUrl}?${newQueryString}`)
    } else {
      return baseUrl
    }
  }

  return trimTrailingSpecialChars(url)
}

// regex to remove trailing & or ? characters.
function trimTrailingSpecialChars(input: string): string {
  return input.replace(/[&?]+$/, "")
}
