import React from "react"
interface ProgressProps {
  label: string
  completed: number
  total: number
  containerWidthClass?: string
  showCount?: boolean
  theme?: "gray" | "blue"
}

export default function Progress({
  label,
  completed,
  total,
  containerWidthClass = "w-full",
  showCount,
  theme = "blue",
}: ProgressProps) {
  // Calculations
  const rawPercent = (completed / total || 0) * 100
  const percent = rawPercent == 100 ? "100" : Number(rawPercent).toFixed(1)

  // Style classes
  const textColor =
    theme === "blue" ? "text-blue-500" : "text-gray-700 dark:text-gray-300"
  const bgColor = theme === "blue" ? "bg-blue-600" : "bg-gray-700 dark:bg-gray-300"
  const bgTrackColor =
    theme === "blue" ? "bg-blue-200" : "bg-muted"

  return (
    <div className={containerWidthClass}>
      <div className="flex items-center justify-between">
        <div>
          <span className={`inline-block ${textColor} mr-2`}>
            {label}
            {showCount && <span className="px-1">{`${completed}/${total}`}</span>}
          </span>
        </div>
        <div className={`text-right ${textColor}`}>
          <span className="text-xs inline-block">{percent}%</span>
        </div>
      </div>
      {/* PROGRESS BAR */}
      <div className={`overflow-hidden h-1 text-xs flex rounded ${bgTrackColor}`}>
        <div
          style={{ width: `${percent}%` }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${bgColor}`}
        ></div>
      </div>
    </div>
  )
}
