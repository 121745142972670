// faq
import { freetextFaqs } from "../configs/faqs/freetext.js"
import { currentsFaqs } from "../configs/faqs/currents.js"
import { triFaqs } from "../configs/faqs/tri.js"
// pricing
import { triPlans } from "../configs/pricing/tri.js"
import { triPlanComparison } from "../configs/pricing/tri.js"
import { ftPlans } from "../configs/pricing/freetext.js"
import { ftPlanComparison } from "../configs/pricing/freetext.js"
import { ftPricingFaqs } from "../configs/pricing/freetext.js"

// footer
import { freetextFooterLinks } from "../components/common/configs/footer/freetext"
import { currentsFooterLinks } from "../components/common/configs/footer/currents"
import { triFooterLinks } from "../components/common/configs/footer/tri"
import { LoginTypeEnum } from "components/common/types"
import { SettingsEnum } from "enums"

import {
  currentsMain,
  freetextMain,
  freetextManage,
  triMain,
  triManage,
  settings,
} from "./menu.js"

export const SITE = process.env.NEXT_PUBLIC_SITE || "freetext"

// TODO
// 1. move all footer related variables to within "footer" key (e.g currents)
// 2. move all menu related variables to within "dashboardMenu" key
// 3. reorganize similarly when required.

const themes = {
  freetext: {
    homePath: "channels",
    //ga: "UA-115950847-1", (older universal analytics)
    ga: "G-DWVESMCXRP",
    favicon: "faviconFt.ico",
    logo: {
      white: "logo-white.svg",
      black: "logo-black.svg",
      "icon-white": "icon-logo-white.svg",
      "icon-black": "icon-logo-black.svg",
      "icon-blue": "icon-logo-blue.svg",
      svgName: "freetext",
    },
    site: "FreeText AI",
    siteShort: "FreeText",
    company: "FreeText AI Pte. Ltd.",
    pricing: { plans: ftPlans, compare: ftPlanComparison, faqs: ftPricingFaqs },
    address: {
      line1: "68 Circular Road",
      line2: "#02-01 Singapore (049422)",
    },
    footerSummary:
      "Convert textual customer feedback from multiple sources - reviews, chats, tickets - into actionable insights and trends in minutes.",
    contactEmail: "support@freetext.ai",
    faqs: freetextFaqs,
    // homeComponent: <FreetextHome />,
    // aboutComponent: <FreetextAbout />,
    menus: {
      menuIcons: freetextMain,
      subMenuManage: freetextManage,
      subMenuSettings: settings,
    },
    footerLinks: freetextFooterLinks,
    headerLinks: [
      {
        name: "About",
        link: "/about",
        icon: "folder",
      },
      {
        name: "Pricing",
        link: "/pricing",
        icon: "pricing",
      },
      {
        name: "FAQs",
        link: "/faqs",
        icon: "question-circle",
      },
      {
        name: "Contact",
        link: "/contact",
        icon: "mail-open",
      },
    ],
    headerCtaLinks: [
      {
        name: "Sign in",
        link: "/login",
      },
      {
        name: "Sign Up",
        link: "/signup",
      },
    ],
    seo: {
      url: "https://freetext.ai",
      logoUrl: "https://freetext.ai/images/ft-logo-112x112-black.png",
      title: "FreeText AI - Customer Feedback Analysis powered by AI",
      description:
        "Convert textual customer feedback from multiple sources - reviews, chats, tickets - into actionable insights and trends in minutes.",
      homeImageUrl: "https://freetext.ai/images/ft-og.png",
      siteName: "FreeText AI",
      locale: "en_US",
    },
    helpLink: "https://freetext.ai/docs",
    blackList: [
      "/onboarding",
      "/enterprise",
      // "/c/.*"
    ], // ---> KEEP THE CONTENTS IN LOWERCASE
    homePageConfig: {
      showSources: true,
      showBookmarks: true,
      showReportsSection: false,
    },
    iconSidenavExpandable: false,
    loginType: [LoginTypeEnum.Login],
    settingsConfig: [
      SettingsEnum.general,
      SettingsEnum.email,
      SettingsEnum.appearance,
    ],
  },

  currents: {
    homePath: "c/docs",
    ga: "G-95ZDKKEN0Y",
    favicon: "faviconCr.ico",
    logo: {
      white: "currents-white.svg",
      black: "currents-black.svg",
      "icon-white": "currents-icon-white.svg",
      "icon-black": "currents-icon-black.svg",
      "icon-blue": "currents-logo-blue.svg",
      svgName: "currents",
    },
    site: "Currents AI",
    siteShort: "Currents",
    company: "Currents AI",
    address: {
      line1: "68 Circular Road",
      line2: "#02-01 Singapore (049422)",
    },
    footer: {
      visible: false,
    },
    footerSummary:
      "Convert reviews, ratings and other eCommerce data into actionable insights within minutes.",
    contactEmail: "support@currents.ai",
    faqs: currentsFaqs,
    // homeComponent: <CurrentsHome />,
    // aboutComponent: <CurrentsAbout />,
    footerLinks: currentsFooterLinks,
    pricing: { plans: triPlans, compare: triPlanComparison },
    menus: {
      menuIcons: currentsMain,
      subMenuSettings: settings,
    },
    headerLinks: [],
    headerCtaLinks: [
      {
        name: "Sign in",
        link: "/login",
      },
      {
        name: "Sign Up",
        link: "/login",
      },
    ],
    seo: {
      url: "https://currents.ai",
      logoUrl: "https://currents.ai/images/currents-112-black.png",
      title: "Write fact-filled articles - Currents AI",
      description:
        "Write fact-filled, seo optimized blog posts and articles in minutes.",
      homeImageUrl: "https://currents.ai/images/currents-og.png",
      siteName: "Currents AI",
      locale: "en_US",
    },
    helpLink: "https://help.freetext.ai",
    blackList: ["/channels"],
    leftBgImageSrc: "/images/landing/left_art.svg",
    rightBgImageSrc: "/images/landing/right_art.svg",
    iconSidenavExpandable: true,
    loginType: [LoginTypeEnum.GoogleLogin, LoginTypeEnum.Magiclink],
    homePageConfig: {
      showSources: false,
      showBookmarks: false,
      showReportsSection: false,
    },
    settingsConfig: [SettingsEnum.appearance],
  },

  tri: {
    homePath: "onboarding",
    ga: "UA-78263144-1",
    //favicon: "favicontri.ico",
    favicon: "tri-favicon-new.ico",
    logo: {
      white: "tri-white-new.svg",
      black: "tri-black-new.svg",
      "icon-white": "tri-icon-white-new.svg",
      "icon-black": "tri-icon-black-new.svg",
      "icon-blue": "tri-icon-blue-new.svg",

      /*
      white: "tri-white.svg",
      black: "tri-black.svg",
      icon-white: "tri-icon-white.svg",
      icon-black: "tri-icon-black.svg",
      icon-blue: "tri-logo-blue.svg",
      */
      svgName: "tri",
    },
    site: "TheReviewIndex",
    siteShort: "TRI",
    company: "TheReviewIndex",
    address: {
      line1: "68 Circular Road",
      line2: "#02-01 Singapore (049422)",
    },
    footerSummary:
      "Convert reviews, ratings and other eCommerce data into actionable insights within minutes.",
    contactEmail: "root@thereviewindex.com",
    pricing: { plans: triPlans, compare: triPlanComparison },
    faqs: triFaqs,
    // homeComponent: <CurrentsHome />,
    // aboutComponent: <CurrentsAbout />,
    footerLinks: triFooterLinks,
    menus: {
      menuIcons: triMain,
      subMenuManage: triManage,
      subMenuSettings: settings,
    },
    headerLinks: [
      {
        name: (
          <div className="flex gap-1">
            <img src="/images/icons/chromeIcon.svg" alt="chrome plugin" />
            Get Chrome Plugin
          </div>
        ),
        link: "https://chrome.google.com/webstore/detail/thereviewindex-reviews-su/fciomokgnajoifiiaglcjkonligobneo",
        icon: "puzzle",
      },
      {
        name: (
          <div className="flex gap-1">
            <img src="/images/icons/firefoxIcon.svg" alt="firefox plugin" />
            Get Firefox Plugin
          </div>
        ),
        link: "https://addons.mozilla.org/en-US/firefox/addon/thereviewindex/",
        icon: "puzzle",
      },
    ],
    mobileLinks: [
      {
        name: "About",
        link: "/about",
        icon: "folder",
      },
      {
        name: "Pricing",
        link: "/pricing",
        icon: "pricing",
      },
      {
        name: "Terms",
        link: "/terms",
        icon: "terms",
      },
      {
        name: "Privacy",
        link: "/privacy",
        icon: "shield",
      },
    ],
    email: "root@TheReviewIndex.com",
    headerCtaLinks: [
      {
        name: "Sign in",
        link: "/login",
      },
      {
        name: "Try Pro For Free!",
        link: "/signup",
      },
    ],
    seo: {
      url: "https://thereviewindex.com/",
      logoUrl: "https://thereviewindex.com/images/tri-112-black.png",
      title: "thereviewindex - Review Analysis for Amazon Reviews",
      description:
        "Summary of thousands of user reviews, organized feature wise, along with pros/cons, for comparison and review analysis.",
      homeImageUrl: "https://thereviewindex.com/images/thereviewindex-og.png",
      siteName: "thereviewindex",
      locale: "en_US",
    },
    helpLink: "https://help.freetext.ai",
    blackList: ["/teams", "/users", "/channels"],
    homePageConfig: {
      showSources: true,
      showBookmarks: true,
      showReportsSection: true,
    },
    loginType: [LoginTypeEnum.Login],
    settingsConfig: [
      SettingsEnum.general,
      SettingsEnum.email,
      SettingsEnum.appearance,
    ],
  },
}

export const THEME = themes[SITE]
