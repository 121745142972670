module.exports = {
    current: "currentColor",
    blue: {
      graph: "#7cb5ec",
      theme: "#0969DA",
    },
    indigo: {
      500: "#6e8cf1",
    },
    gray: {
      100: "#f7fafc",
      200: "#edf2f7",
      300: "#e2e8f0",
      400: "#cbd5e0",
      500: "#a0aec0",
      600: "#718096",
      700: "#4a5568",
      800: "#2d3748",
      900: "#1a202c",
    },
    green: {
      500: "#12c457",
      250: "#07a15e",
    },
    yellow: {
      "hl-100": "#ffff80",
      "hl-200": "yellow",
    },
}
