import { ErrorCodes } from "@common/lib/forms.utils"
import { isNullOrEmpty, recordErrorInForm } from "@common/lib/util"
import { Status } from "@common/types"
import configuration from "configuration"
import { isFilterInPath } from "lib/filters"

export const fetcher = async (...args) => {
  const response = await fetch(...args)
  const parsedResponse = await response?.json()
  if (!response.ok) {
    handleServerErrors(response.status, parsedResponse)
  }
  return handleResponse(parsedResponse)
}

export const postFetcher = async (url, body, _headers, method, ...args) => {
  const headers = _headers ? _headers : { "Content-type": "application/json" }
  const response = await fetch(url, {
    method: method || "POST",
    body,
    headers,
    ...args,
  })
  let parsedResponse = null
  try {
    parsedResponse = await response?.json()
  } catch (err) {
    console.log("err", err)
  }
  if (!response.ok) {
    handleServerErrors(response.status, parsedResponse)
  }
  return handleResponse(parsedResponse)
}

export async function handleError(error, source = "unknown") {
  const errorDetails = {
    message: error.message || "An unknown error occurred (CATCH)",
    stack: error.stack || "No stack trace available",
    timestamp: new Date().toISOString(),
    source,
  }
  recordErrorInForm(errorDetails.message, errorDetails)

  return {
    data: null,
    isError: JSON.parse(JSON.stringify(error)),
  }
}

export const options = { revalidateOnFocus: false }
export const autoRefreshSWROptions = {
  revalidateOnFocus: false,
  refreshInterval: 5000,
  errorRetryCount: 2,
}

export function getFullUrl(url) {
  // to run in prod mode in local, uncomment below line and change prod config to be same as defauly config
  //return `${configuration.webserver.address}/${url}`
  return process.env.NODE_ENV == "production"
    ? typeof window == "undefined"
      ? `${configuration.webserver.address}/${url}`
      : `/${url}`
    : `${configuration.webserver.address}/${url}`
}

/**
 * @returns {any}
 */
function handleResponse(parsedResponse) {
  const standardResponse = {
    data: null,
    status: null,
    message: null,
  }
  if (!parsedResponse) {
    return standardResponse
  } else {
    standardResponse.data = parsedResponse
    if (parsedResponse.status === Status.Otp) {
      standardResponse.status = parsedResponse.status
      standardResponse.message = getAndRecordResponseMessage(parsedResponse, false)
    } else if (
      parsedResponse.status === "FAILURE" ||
      parsedResponse.status === "UNAUTHORIZED" ||
      parsedResponse.status === "ERROR"
    ) {
      standardResponse.status = Status.Failure
      standardResponse.message = getAndRecordResponseMessage(parsedResponse)
    } else {
      standardResponse.status = Status.Success
      standardResponse.message = getAndRecordResponseMessage(parsedResponse, false)
    }
    return standardResponse
  }
}

function handleServerErrors(statusCode, parsedResponse) {
  const statusMessages = {
    403: "Oops! It looks like you don't have permission for this action. Please check your credentials and try again.",
    413: "Our apologies, the request seems too large to process. Could you try with a smaller request?",
    502: "Hmm, we seem to be having trouble connecting to the service. We're on it, and please try again shortly!",
    500: "We're experiencing some technical difficulties on our end. Rest assured, we're looking into it right away!",
  }
  if (typeof window !== "undefined") {
    getAndRecordResponseMessage(parsedResponse)
    if (statusCode === 401) {
      const { href = "" } = window.location
      const url = encodeURIComponent(href)
      if (!isFilterInPath(window.location, "url") && !href.includes("/login")) {
        window.location.href = `/login?url=${url}` // Redirect to login
      }
    }
  }
  if (statusCode in statusMessages) {
    showNetworkToast(statusMessages[statusCode])
  }
  // throw new Error("Server error occurred")
}

function getAndRecordResponseMessage(data, isRecordMessage = true) {
  let message = null
  if (data) {
    const {
      errorCode,
      user_message,
      error_message, // 🚦 User friendly UI messages.
      internal_error_message,
      display_messages,
    } = data

    // Determine the most appropriate error message to display
    if (user_message) {
      message = user_message
    } else if (error_message) {
      message = error_message
    } else if (display_messages?.overall?.errors) {
      message = !isNullOrEmpty(display_messages.overall.errors)
        ? display_messages.overall.errors
        : null
    } else if (errorCode && errorCode in ErrorCodes) {
      message = ErrorCodes[errorCode]
    }
    // if ((message || internal_error_message) && isRecordMessage) { // as this did not log server error with no message 
    if (isRecordMessage) {
      recordErrorInForm(
        message,
        ` ${
          internal_error_message
            ? `internal_error_message-${internal_error_message}`
            : data
              ? `data-${JSON.stringify(data)}`
              : `data-undefined`
        }`
      )
    }
  }

  return message
}

//exceptional usecase, keep it here to discourage calling toasts via window events elsewhere.
function showNetworkToast(message, type = "error") {
  const event = new CustomEvent("show-toast", {
    detail: { message, options: { type } },
  })
  window.dispatchEvent(event)
}
