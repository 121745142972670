import {
  fetcher,
  getFullUrl,
  handleError,
  options,
  postFetcher,
} from "network/utils"
import useSWR from "swr"
import { getHashFromObject } from "lib/util"

export async function get(apiUrl, skipFullUrlConstruction = false, ...args) {
  const url = skipFullUrlConstruction == true ? apiUrl : getFullUrl(apiUrl)
  try {
    return await fetcher(url, ...args)
  } catch (error) {
    return handleError(error, `${url}`)
  }
}

export async function post(apiUrl, details, headers, method) {
  const url = getFullUrl(apiUrl)
  const detailsStr = JSON.stringify(details)
  try {
    return await postFetcher(url, detailsStr, headers, method)
  } catch (error) {
    return handleError(error, `${url}`)
  }
}

export function getSwr(
  apiUrl,
  shouldFetch = true,
  ftchr = fetcher,
  shouldMutate = false,
  fetchOptions = options
) {
  if (ftchr == null) ftchr = fetcher
  const url = getFullUrl(apiUrl)
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    shouldFetch ? url : null,
    ftchr,
    fetchOptions
  )
  if (shouldMutate == true) mutate()

  return {
    data: data?.data,
    isLoading,
    isError: error,
    isValidating,
    mutate,
    message: data?.message,
    status: data?.status,
  }
}

export function postSwr(
  apiUrl,
  details,
  shouldFetch = true,
  shouldMutate = false,
  appendHash = false,
  fetchOptions = options
) {
  const detailsStr = JSON.stringify(details)
  const detailsHash = getHashFromObject(detailsStr) // this function also removes empty/undefined variables from object. Otherwise, multiple n/w calls are generated each time details are different because it may or may not contain empty variables.
  if (appendHash == true) {
    apiUrl += `_${detailsHash}`
  }
  const url = getFullUrl(apiUrl)

  const { data, error, mutate, isValidating, isLoading } = useSWR(
    shouldFetch ? [url, detailsHash] : null,
    () => postFetcher(url, detailsStr),
    fetchOptions
  )
  if (shouldMutate == true) {
    mutate()
  }

  return {
    data: data?.data,
    isLoading: isLoading,
    isError: error,
    message: data?.message,
    status: data?.status,
    mutate,
    isValidating,
  }
}

// to fetch something on server side
export async function getSSR(cookie, apiUrl) {
  const url = getFullUrl(apiUrl)
  try {
    const response = await fetch(url, cookie)
    const data = await response.json()
    const resCode = response.status

    return {
      data: data,
      resCode: resCode,
      isError: null,
    }
  } catch (error) {
    return {
      data: null,
      isError: JSON.parse(JSON.stringify(error)),
    }
  }
}

export async function remove(apiUrl, details) {
  return await post(apiUrl, details, null, "DELETE")
}

export async function update(apiUrl, details) {
  return await post(apiUrl, details, null, "PUT")
}

export function connect(apiUrl, details) {
  return post(apiUrl, details)
}
