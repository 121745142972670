export const freetextFooterLinks = {
  simple: {
    links: [
      { name: "About", link: "https://freetext.ai/about" },
      { name: "Contact", link: "https://freetext.ai/contact" },
      { name: "Blog", link: "https://freetext.ai/blog" },
      { name: "FAQs", link: "https://freetext.ai/faqs" },
      { name: "Privacy Hub", link: "https://freetext.ai/trust" },
      { name: "Privacy Policy", link: "https://freetext.ai/privacy" },
      { name: "Terms of Use", link: "https://freetext.ai/terms" },
    ],
  },
  primary: {
    1: {
      name: "Company",
      links: [
        { name: "About", link: "https://freetext.ai/about" },
        { name: "Contact", link: "https://freetext.ai/contact" },
        { name: "Blog", link: "https://freetext.ai/blog" },
        { name: "FAQs", link: "https://freetext.ai/faqs" },
        { name: "Privacy Hub", link: "https://freetext.ai/trust" },
        { name: "Privacy Policy", link: "https://freetext.ai/privacy" },
        { name: "Terms of Use", link: "https://freetext.ai/terms" },
      ],
    },
    2: {
      name: "Channels",
      links: [
        { name: "Zendesk", link: "https://freetext.ai/channels/zendesk" },
        // {name: "Intercom", link: "https://freetext.ai/channels/intercom",},
        // { name: "TypeForm", link: "https://freetext.ai/channels/typeform" },
        // { name: "HubSpot", link: "https://freetext.ai/channels/hubspot" },
        // {name: "Survey Monkey", link: "https://freetext.ai/channels/surveymonkey",},
        // { name: "Salesforce", link: "https://freetext.ai/channels/salesforce", },
      ],
    },
    3: {
      name: "Use Cases",
      links: [
        {
          name: "Amazon Review Analysis",
          link: "https://freetext.ai/blog/review-analysis-for-product-reviews",
        },
        { name: "App Store", link: "" },
        { name: "Chat Transcripts", link: "" },
        { name: "CSAT / NPS Survey", link: "" },
        { name: "Support Tickets", link: "" },
      ],
    },
    4: {
      name: "Verticals",
      links: [
        { name: "Electronics", link: "" },
        { name: "Hospitality", link: "" },
        { name: "Lifestyle", link: "" },
        { name: "Gaming", link: "" },
        { name: "FMCG", link: "" },
        { name: "SaaS", link: "" },
      ],
    },
    5: {
      name: "Capabilities",
      links: [
        { name: "Data Aggregation", link: "" },
        { name: "Feedback Analytics", link: "" },
        { name: "Topic Classification", link: "" },
        { name: "Sentiment Analysis", link: "" },
        { name: "Product Analysis", link: "" },
      ],
    },
  },
}
