import { ReactNode } from "react"
import { LabelProps } from "@common/forms/label"

export enum LoginTypeEnum {
  "GoogleLogin",
  "Login",
  "Magiclink",
}


// ┌┬┐┬─┐┌─┐┌─┐┌┬┐┌─┐┬ ┬┌┐┌ dropdown type starts
//  ││├┬┘│ │├─┘ │││ │││││││ ↓
// ─┴┘┴└─└─┘┴  ─┴┘└─┘└┴┘┘└┘ ↓
export type ListBoxProps = Pick<LabelProps, "required" | "id"> &  {
  defaultSelectedKey?: string
  onSelect?: (key: any, event?: any) => void
  options: Options
  disabled?: boolean
  enableSearch?: boolean
  showSingleItem?: boolean
  isLoading?: boolean
  sortOptions?: boolean
  placeHolderText?: string
  buttonWidthClasses?: string
  optionWidthClasses?: string
  enableReset?: boolean
  resetText?: string
  label?: LabelProps
}
export enum sectionException {
  other = "unsectioned",
}
export interface Option {
  key: string
  display: ReactNode
  disabled?: boolean
  invisible?: boolean
  section_key?: string
  icon?: ReactNode // string (iconname) or jsx
}
export interface Options {
  [key: string]: Option
}
export interface groupedOptions {
  [key: string]: Option[]
}

export type Action = Option & {
  isIconOutside?: boolean
}
export type Actions = Action[]
export interface ActionMenuProps {
  actions?: Actions
  showIconMenu?: boolean
  showPopoverMenu?: boolean
  onActionClick?: (option: Action) => void
  buttonClasses?: string
  popoverIcon?: string
  showIconMenuOnHover?: boolean
  strategy?: "fixed" | "absolute"
}
// ┌┬┐┬─┐┌─┐┌─┐┌┬┐┌─┐┬ ┬┌┐┌ ↑
//  ││├┬┘│ │├─┘ │││ │││││││ ↑
// ─┴┘┴└─└─┘┴  ─┴┘└─┘└┴┘┘└┘ dropdown type ends



export interface TabProps {
  isSelected?: boolean
  isCompleted?: boolean
  index?: number
  onSelect: (index: number) => void
  icon?: string
  tabSize?: "small" | "default"
  children?: ReactNode // children or label can be used.
}
export type TabGroupProps = {
  tabs: Record<
    string,
    {
      display: ReactNode
    }
  >
  defaultTabKey?: string
  onSelect: (tab: string) => void
} & Pick<TabProps, "tabSize">

/**** API *****/
export enum Status {
  Failure = "FAILURE",
  Success = "SUCCESS",
  Otp = "OTP_PENDING",
  Error = "ERROR"
}

export type Message = {
  errors?: string[]
  warnings?: string[]
}

export type DisplayMessage = {
  overall: Message
  [key: string]: Message
}

export type APIResponse = {
  status: Status
  display_messages?: DisplayMessage
  user_message?: string
}

/**** Cards ****/
// TODO
// 1. change names (header, description, url, anchor, imgUrl)
export interface CardProps {
  icon?: ReactNode
  id?: string
  cardName?: string
  cardType?: "flatCard" | "iconCard" | "imageCard" | string
  cardDescription?: string
  cardUrl?: string
  newTab?: boolean
  wrapLink?: boolean
  onCardClick?: (id: string | undefined) => void
  disabled?: boolean
  disabledMsg?: string
  cardImage?: ReactNode //can be a string (path) or jsx
  fallBackImg?: string
  sectionKey?: string
  actionMenu?: ActionMenuProps
  showTooltip?: boolean
  cardImageDark?: string
  isDarkMode?: boolean

  // for flat card
  initials?: string[]
  cardWidthClasses?: string

  // for image card
  // TODO -standadise these actions with actionsMenu
  hasIcon?: boolean
  cardIcons?: string[] | undefined
  cardActions?: {
    [key: string]: {
      url?: string
      display?: string
      onClick?: (id: string, actionKey: string) => void
    }
  }

  alignment?: "left" | "center" | "right"
}

declare global {
  /**
   * This snippet extends the global Window interface in TypeScript to include the 'grecaptcha' property.
   * `grecaptcha` is a part of the Google `reCAPTCHA` API which is loaded from `react-google-recaptcha` and not be recognized by TypeScript by default.
   * By declaring 'grecaptcha' as 'any', we bypass the strict type checking, thus preventing TypeScript compiler errors when accessing this property.
   */
  interface Window {
    grecaptcha: any
  }
}
